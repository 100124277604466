import React, { useContext, useEffect, useRef, useState } from 'react';

import { App, Dropdown, Flex, Menu, Switch, Table, Tooltip } from 'antd';
import { createStyles } from 'antd-style';
import { ColumnsType } from 'antd/es/table';
import { Link } from 'react-router-dom';

import { isArray } from 'lodash';

import RoundButton from 'Components/Molecules/Buttons/RoundButton';
import GalleryJobTitle from 'Components/Molecules/GalleryJobTitle';
import GalleryBulkEdit from 'Components/Molecules/Notification/GalleryBulkEdit';

import { Colors } from 'Themes';

import { LocalizationContext } from 'i18n';

import dayjs from 'Services/DayjsService';

import {
  GalleriesGetWhereType,
  GalleryOrderType,
  GalleryStatus,
  GetGalleriesQuery
} from 'Operations/__generated__/graphql';

const GALLERY_URL = process.env.REACT_APP_GALLERY_URL;

export interface ContactType {
  id: number;
  name: string;
  color: string;
}

export interface HandleOnChangeProps {
  page: number;
  perPage: number;
  order: 'asc' | 'desc';
  status?: GalleryStatus[];
  search?: string;
}

export interface HandleChangeGalleryStatusProps {
  id: number;
  isChecked: boolean;
}

export interface ShowGalleryProps {
  slug: string;
  url: string;
}

interface Props {
  galleriesTotal: number;
  loading: boolean;
  paginationCurrent: number;
  paginationSize?: number;
  data?: GetGalleriesQuery['getPaginatedGalleries']['edges'];
  galleryIdUpload?: number;
  isUploadRunning: boolean;
  handleOnChange: (params: GalleriesGetWhereType) => void;
  handleShowGalleryClick?: (params: ShowGalleryProps) => void;
  handleDeleteConfirm: (params: { ids: number[] }) => void;
  handleArchiveConfirm: (params: { ids: number[] }) => void;
  handleChangeGalleryStatus?: (params: HandleChangeGalleryStatusProps) => void;
}

const useStyles = createStyles(({ css, token }) => ({
  galleryName: css`
    display: block;
    color: ${token.colorText};
    padding: 0 ${token.sizeSM}px;

    &:hover {
      color: ${token.colorPrimary};
    }
  `,
}));

const statuses: string[] = [GalleryStatus.ONLINE.toString(), GalleryStatus.OFFLINE.toString()];

const GalleryTable = ({
  loading,
  galleriesTotal,
  data,
  paginationCurrent,
  paginationSize = 20,
  galleryIdUpload,
  isUploadRunning,
  handleOnChange,
  handleShowGalleryClick,
  handleDeleteConfirm,
  handleArchiveConfirm,
  handleChangeGalleryStatus,
}: Props) => {
  const { t } = useContext(LocalizationContext);
  const { modal, notification } = App.useApp();
  const notificationIdentifier = useRef<string | null>(null);
  const { styles } = useStyles();

  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

  useEffect(() => {
    if (selectedRowKeys.length > 0) {
      notificationIdentifier.current = 'edit-table';
      notification.open({
        key: notificationIdentifier.current,
        placement: 'bottomRight',
        duration: 0,
        className: 'ant-notification--bulk-edit',
        onClose: () => setSelectedRowKeys([]),
        message: (
          <GalleryBulkEdit
            selectedRowsIds={selectedRowKeys as number[]}
            onArchive={handleArchiveConfirm}
            onDelete={handleDeleteConfirm}
            isUploadRunning={isUploadRunning}
            galleryIdUpload={galleryIdUpload}
          />
        ),
      });
    } else {
      if (notificationIdentifier.current) {
        notification?.destroy(notificationIdentifier.current);
        notificationIdentifier.current = null;
        setSelectedRowKeys([]);
      }
    }
  }, [galleryIdUpload, isUploadRunning, handleArchiveConfirm, handleDeleteConfirm, selectedRowKeys, t]);

  useEffect(() => {
    return () => {
      if (notificationIdentifier.current) {
        notification?.destroy(notificationIdentifier.current);
        notificationIdentifier.current = null;
        setSelectedRowKeys([]);
      }
    };
  }, [galleriesTotal]);

  const columns: ColumnsType<GetGalleriesQuery['getPaginatedGalleries']['edges'][0]> = [
    {
      title: t('app.common.actions'),
      key: 'operation',
      width: GALLERY_URL ? 168 : 120,
      render: (_, record) => (
        <Flex justify="space-between">
          <RoundButton icon="edit" tooltipTitle={t('app.common.edit')} href={`/app/galleries/${record.id}`} />
          {GALLERY_URL && (
            <RoundButton
              icon="external-link"
              tooltipTitle={t('app.common.showGallery')}
              onClick={() => {
                if (handleShowGalleryClick) {
                  handleShowGalleryClick({ slug: record.slug, url: record.url });
                }
              }}
            />
          )}

          <Dropdown
            key="more"
            disabled={loading}
            menu={{
              items: [
                {
                  key: 'archive',
                  label: `${t('app.common.archive')} ${
                    isUploadRunning && galleryIdUpload === record.id ? ` - ${t('app.gallery.archive.disabled')}` : ''
                  }`.trim(),
                  disabled: isUploadRunning && galleryIdUpload === record.id,
                  onClick: () => {
                    return modal.confirm({
                      title: t('app.gallery.archive.title', { count: 1 }),
                      content: t('app.gallery.archive.content'),
                      okText: t('app.common.archive'),
                      cancelText: t('app.common.cancel'),
                      onOk() {
                        handleArchiveConfirm({ ids: [record.id] });
                      },
                    });
                  },
                },
                {
                  key: 'delete',
                  label: `${t('app.gallery.moveToTrash')} ${
                    isUploadRunning && galleryIdUpload === record.id ? ` - ${t('app.gallery.delete.disabled')}` : ''
                  }`.trim(),
                  danger: true,
                  disabled: isUploadRunning && galleryIdUpload === record.id,
                  onClick: () => {
                    return modal.confirm({
                      title: t('app.confirm.moveToTrash', { count: 1 }),
                      okText: t('app.gallery.moveToTrash'),
                      cancelText: t('app.common.cancel'),
                      onOk() {
                        handleDeleteConfirm({ ids: [record.id] });
                      },
                    });
                  },
                },
              ],
            }}
            placement="bottomRight"
          >
            <RoundButton size="large" icon="more" />
          </Dropdown>
        </Flex>
      ),
    },
    {
      title: t('app.common.name'),
      dataIndex: 'name',
      key: 'name',
      render: (name, record) => (
        <GalleryJobTitle jobs={record.jobs}>
          <Link className={styles.galleryName} to={`/app/galleries/${record.id}`}>
            {name}
          </Link>
        </GalleryJobTitle>
      ),
    },
    {
      title: t('app.common.contact'),
      dataIndex: 'contact',
      key: 'contact',
      render: (_, record) => record?.contact?.displayName,
    },
    {
      title: t('app.common.status'),
      dataIndex: 'status',
      key: 'status',
      filters: statuses.map(status => ({
        text: t(`app.gallery.status.${status.toLowerCase()}`),
        value: status,
      })),
      onFilter: (value, record) => {
        if (value === record.status) {
          return true;
        }

        return false;
      },
      render: (text, { id, status }) => (
        <Switch
          style={{ backgroundColor: status === GalleryStatus.ONLINE ? Colors.success : Colors.error }}
          checkedChildren={t(`app.gallery.status.online`)}
          unCheckedChildren={t(`app.gallery.status.offline`)}
          checked={status === GalleryStatus.ONLINE}
          onChange={checked => {
            if (handleChangeGalleryStatus) {
              handleChangeGalleryStatus({
                id,
                isChecked: checked,
              });
            }
          }}
        />
      ),
    },
    {
      title: t('app.common.usedSpace'),
      dataIndex: 'usedSpace',
      key: 'usedSpace',
      render: text => `${text} GB`,
    },
    {
      title: t('app.common.createdAt'),
      dataIndex: 'createdAt',
      key: 'createdAt',
      sortDirections: ['descend', 'ascend', 'descend'],
      defaultSortOrder: 'descend',
      sorter: (a, b) => (a && b ? dayjs(a?.createdAt).unix() - dayjs(b?.createdAt).unix() : 0),
      render: text => dayjs(text).format('LLL'),
    },
  ];

  return (
    <Table
      rowKey="id"
      dataSource={data}
      columns={columns}
      scroll={{ x: true }}
      loading={loading}
      pagination={{
        total: galleriesTotal,
        pageSize: paginationSize,
        current: paginationCurrent,
        showSizeChanger: false,
        position: ['bottomCenter'],
      }}
      rowSelection={{
        selectedRowKeys,
        onChange: setSelectedRowKeys,
      }}
      onChange={(pagination, filters, sorter) => {
        let order: GalleryOrderType = GalleryOrderType.DESC;

        if (isArray(sorter)) {
          order = sorter[0].order === 'ascend' ? GalleryOrderType.ASC : GalleryOrderType.DESC;
        } else {
          order = sorter.order === 'ascend' ? GalleryOrderType.ASC : GalleryOrderType.DESC;
        }

        const params: GalleriesGetWhereType = {
          page: pagination.current ? pagination.current : 1,
          perPage: pagination.pageSize ? pagination.pageSize : 20,
          order,
        };

        if (filters.status) {
          const filterStatuses = filters.status.map(s =>
            s === 'OFFLINE' ? GalleryStatus.OFFLINE : GalleryStatus.ONLINE,
          );
          params.status = filterStatuses;
        }

        handleOnChange(params);
      }}
    />
  );
};

export default GalleryTable;
