import React from 'react';

import { Link } from 'react-router-dom';

import Text from 'Components/Atoms/Text';
import TextLink from 'Components/Atoms/TextLink';

import { Colors } from 'Themes';
import styled from 'Themes/Styled';

export interface BreadcrumbContent {
  text: string;
  url?: string;
}

export interface Props {
  content: BreadcrumbContent[];
  className?: string;
}

const BreadcrumbContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const StyledSpan = styled.span`
  color: ${Colors.grey};
`;

const Breadcrumb = ({ content, ...props }: Props) => {
  const renderBreadcrumb = () => {
    return content.map(({ text, url }, index) => {
      if (url) {
        return (
          <React.Fragment key={`fragment-${index}`}>
            {index > 0 && <StyledSpan key={`separator-${index}`}>{'\u00A0>\u00A0'}</StyledSpan>}
            <TextLink as={Link} key={`${text}-${index}`} to={url} color={Colors.grey}>
              {text}
            </TextLink>
          </React.Fragment>
        );
      } else {
        return (
          <React.Fragment key={`fragment-${index}`}>
            {index > 0 && <StyledSpan key={`separator-${index}`}>{'\u00A0>\u00A0'}</StyledSpan>}
            <Text key={`${text}-${index}`} size="medium" color={Colors.grey}>
              {text}
            </Text>
          </React.Fragment>
        );
      }
    });
  };

  return <BreadcrumbContainer {...props}>{renderBreadcrumb()}</BreadcrumbContainer>;
};

export default Breadcrumb;
