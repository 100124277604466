import { InMemoryCache, makeVar, TypePolicies } from '@apollo/client';
import { Tutorial } from 'types/Tutorial';

import { orderBy, uniqBy } from 'lodash';

import mergeList from 'Operations/Cache/Helpers/mergeList';
import readList from 'Operations/Cache/Helpers/readList';

import { StrictTypedTypePolicies } from 'Operations/__generated__/apollo-helpers';
import {
  DisplayType,
  GetAccessCodesQueryVariables,
  GetCatalogsQueryVariables,
  GetClientsQueryVariables,
  GetContactsQueryVariables,
  GetEmailTemplatesQueryVariables,
  GetFeatureRequestsQueryVariables,
  GetGalleriesQueryVariables,
  GetGalleryPresetsQueryVariables,
  GetInvoicesQueryVariables,
  GetNotificationsQueryVariables,
  GetOrdersQueryVariables,
  GetPicklistsQueryVariables,
  GetProductCategoriesQueryVariables,
  GetProductsQueryVariables,
  GetProjectsQueryVariables,
  GetVisitorsQueryVariables,
} from 'Operations/__generated__/graphql';

import CatalogPolicy from './Policies/Catalog';
import ContactPolicy from './Policies/Contact';
import EmailTemplatePolicy from './Policies/EmailTemplate';
import FeatureRequestPolicy from './Policies/FeatureRequest';
import FolderPolicy from './Policies/Folder';
import GalleryPolicy from './Policies/Gallery';
import PhotoPolicy from './Policies/Photo';
import PicklistPolicy from './Policies/Picklist';
import PricingPlanMetaPolicy from './Policies/PricingPlanMeta';

export const AuthVar = makeVar<{
  isLoggedIn: boolean;
  isSupport: boolean;
  accessToken?: string;
  refreshToken?: string;
  createdAt?: number;
}>({
  isLoggedIn: false,
  isSupport: false,
  accessToken: undefined,
  refreshToken: undefined,
  createdAt: undefined,
});

export const AppVar = makeVar<{
  hasFreeSpace: boolean;
}>({
  hasFreeSpace: true,
});

export const UserConfigVar = makeVar<{
  galleriesDisplay: DisplayType;
  isMenuCollapsed: boolean;
  openedTutorials: Record<Tutorial, boolean>;
}>({
  galleriesDisplay: DisplayType.TABLE,
  isMenuCollapsed: false,
  openedTutorials: {
    [Tutorial.GalleryPresets]: false,
  },
});

const typePolicies: StrictTypedTypePolicies = {
  AccessCode: {
    keyFields: ['code', 'galleryId'],
  },
  Contact: ContactPolicy,
  Folder: FolderPolicy,
  AssetAdmin: {
    keyFields: false,
    merge: true,
  },
  PhotoAdmin: PhotoPolicy,
  GalleryAdmin: GalleryPolicy,
  EmailTemplate: EmailTemplatePolicy,
  FeatureRequest: FeatureRequestPolicy,
  Catalog: CatalogPolicy,
  UploadDetails: {
    keyFields: false,
  },
  DeliveryPriceByZone: {
    keyFields: ['zoneId', 'deliveryWeightRangeId'],
  },
  PricingPlanMeta: PricingPlanMetaPolicy,
  Picklist: PicklistPolicy,
  Query: {
    fields: {
      auth: {
        read() {
          return AuthVar();
        },
      },
      app: {
        read() {
          return AppVar();
        },
      },
      getWatermarks: {
        keyArgs: false,
        merge(existing, incoming) {
          if (incoming.edges > 0) {
            return;
          }

          return {
            __typename: 'WatermarkConnection',
            _count: incoming._count,
            edges: existing
              ? orderBy(uniqBy([...existing.edges, ...incoming.edges], '__ref'), 'createdAt', 'desc')
              : incoming.edges,
          };
        },
      },
      getFolders: {
        merge(existing = [], incoming = []) {
          if (incoming.length < existing.length) {
            return incoming;
          }
          return uniqBy([...incoming, ...existing], '__ref');
        },
      },
      getContacts: {
        keyArgs: (variables, context) => {
          const {
            where: { hasEmail, search, lastname },
            paginate,
          } = (variables as GetContactsQueryVariables) || {};
          const queryName = context.field?.alias?.value;

          return `${queryName || context.fieldName}:${JSON.stringify({
            hasEmail,
            search,
            lastname,
            order: paginate?.order,
          })}`;
        },
        merge(existing, incoming, { variables }) {
          const { paginate } = (variables as GetContactsQueryVariables) || {};

          return mergeList({ existing, incoming, page: paginate?.page, perPage: paginate?.perPage });
        },
        read(existing, { variables, storeFieldName, readField }) {
          const { paginate } = (variables as GetContactsQueryVariables) || {};

          return readList({
            readField,
            storeFieldName,
            existing,
            page: paginate?.page,
            perPage: paginate?.perPage,
            alias: 'getPaginatedContacts',
            defaultOrderFields: ['displayName'],
          });
        },
      },
      getProducts: {
        keyArgs: (variables, context) => {
          const {
            where: { search, categoryIds, catalogId, not },
          } = (variables as GetProductsQueryVariables) || {};
          const queryName = context.field?.alias?.value;

          return `${queryName || context.fieldName}:${JSON.stringify({
            search,
            categoryIds,
            catalogId,
            not,
          })}`;
        },
        merge(existing, incoming, { variables }) {
          const {
            where: { page, perPage },
          } = (variables as GetProductsQueryVariables) || {};

          return mergeList({ existing, incoming, page, perPage });
        },
        read(existing, { variables, storeFieldName, readField }) {
          const {
            where: { page, perPage },
          } = (variables as GetProductsQueryVariables) || {};

          return readList({
            readField,
            storeFieldName,
            existing,
            page,
            perPage,
            defaultOrderFields: ['name'],
            order: ['asc'],
          });
        },
      },
      getOrders: {
        keyArgs: (variables, context) => {
          const {
            where: {
              search,
              status,
              characteristics,
              galleryId,
              crossAccounts: isCrossAccounts,
              isRetouched,
              isValidated,
            },
          } = (variables as GetOrdersQueryVariables) || {};
          const queryName = context.field?.alias?.value;

          return `${queryName || context.fieldName}:${JSON.stringify({
            galleryId,
            search,
            status,
            characteristics,
            isCrossAccounts,
            isRetouched,
            isValidated,
          })}`;
        },
        merge(existing, incoming, { variables }) {
          const {
            where: { page, perPage },
          } = (variables as GetOrdersQueryVariables) || {};

          return mergeList({ existing, incoming, page, perPage });
        },
        read(existing, { variables, storeFieldName, readField }) {
          const {
            where: { page, perPage },
          } = (variables as GetOrdersQueryVariables) || {};

          return readList({
            readField,
            storeFieldName,
            existing,
            page,
            perPage,
            defaultOrderFields: ['createdAt'],
            order: ['desc'],
          });
        },
      },
      getProductCategories: {
        keyArgs: (variables, context) => {
          const {
            where: { search },
          } = (variables as GetProductCategoriesQueryVariables) || {};
          const queryName = context.field?.alias?.value;

          return `${queryName || context.fieldName}:${JSON.stringify({ search })}`;
        },
        merge(existing, incoming, { variables }) {
          const {
            where: { page, perPage },
          } = (variables as GetProductCategoriesQueryVariables) || {};

          return mergeList({ existing, incoming, page, perPage });
        },
        read(existing, { variables, storeFieldName, readField }) {
          const {
            where: { page, perPage },
          } = (variables as GetProductCategoriesQueryVariables) || {};

          return readList({
            readField,
            storeFieldName,
            existing,
            page,
            perPage,
            alias: 'getProductCategories',
            defaultOrderFields: ['name'],
          });
        },
      },
      getGalleries: {
        keyArgs: (variables, context) => {
          const {
            where: { order, field, status, delete: isDeleted },
          } = (variables as GetGalleriesQueryVariables) || { where: {} };
          const queryName = context.field?.alias?.value;

          return `${queryName || context.fieldName}:${JSON.stringify({ order, field, status, isDeleted })}`;
        },
        merge(existing, incoming, { variables }) {
          const {
            where: { page, perPage },
          } = (variables as GetGalleriesQueryVariables) || {};

          return mergeList({ existing, incoming, page, perPage });
        },
        read(existing, { variables, storeFieldName, readField }) {
          const {
            where: { page, perPage, field, order },
          } = (variables as GetGalleriesQueryVariables) || {};

          return readList({
            readField,
            storeFieldName,
            existing,
            page,
            perPage,
            alias: 'getPaginatedGalleries',
            defaultOrderFields: [field || 'name'],
            order: order ? [order] : undefined,
          });
        },
      },
      getProjects: {
        keyArgs: (variables, context) => {
          const { where } = (variables as GetProjectsQueryVariables) || { where: {} };
          const { order, search } = where || {};
          const queryName = context.field?.alias?.value;

          return `${queryName || context.fieldName}:${JSON.stringify({ order, search })}`;
        },
        merge(existing, incoming, { variables }) {
          const { where } = (variables as GetProjectsQueryVariables) || { where: {} };
          const { page, perPage } = where || {};

          return mergeList({ existing, incoming, page, perPage });
        },
        read(existing, { variables, storeFieldName, readField }) {
          const { where } = (variables as GetProjectsQueryVariables) || { where: {} };
          const { page, perPage } = where || {};

          return readList({
            readField,
            storeFieldName,
            existing,
            page,
            perPage,
            defaultOrderFields: ['name'],
          });
        },
      },
      getEmailTemplates: {
        keyArgs: (variables, context) => {
          const {
            where: { associatedModel, order },
          } = (variables as GetEmailTemplatesQueryVariables) || {};
          const queryName = context.field?.alias?.value;

          return `${queryName || context.fieldName}:${JSON.stringify({ associatedModel, order })}`;
        },
        merge(existing, incoming, { variables }) {
          const {
            where: { page, perPage },
          } = (variables as GetEmailTemplatesQueryVariables) || {};

          return mergeList({ existing, incoming, page, perPage });
        },
        read(existing, { variables, storeFieldName, readField }) {
          const {
            where: { page, perPage },
          } = (variables as GetEmailTemplatesQueryVariables) || {};

          return readList({
            readField,
            storeFieldName,
            existing,
            page,
            perPage,
            alias: 'getPaginatedEmailTemplates',
          });
        },
      },
      getCatalogs: {
        keyArgs: (variables, context) => {
          const queryName = context.field?.alias?.value;

          return `${queryName || context.fieldName}`;
        },
        merge(existing, incoming, { variables }) {
          const { where } = (variables as GetCatalogsQueryVariables) || {};

          return mergeList({ existing, incoming, page: where?.page, perPage: where?.perPage });
        },
        read(existing, { variables, storeFieldName, readField }) {
          const { where } = (variables as GetCatalogsQueryVariables) || {};

          return readList({
            readField,
            storeFieldName,
            existing,
            page: where?.page,
            perPage: where?.perPage,
            alias: 'getPaginatedCatalogs',
            defaultOrderFields: ['createdAt'],
          });
        },
      },
      ...(GalleryPolicy?.fields
        ? {
            getPhotos: GalleryPolicy.fields.photos,
            getLatestCommentedPhotos: GalleryPolicy.fields.photos,
            getUnpickedPhotos: GalleryPolicy.fields.photos,
          }
        : {}),
      getNotifications: {
        keyArgs(variables, context) {
          return context.fieldName;
        },
        merge(existing, incoming, { variables }) {
          const { where } = (variables as GetNotificationsQueryVariables) || {};

          return mergeList({ existing, incoming, page: where?.page, perPage: where?.perPage });
        },
      },
      getUploadUrl: {
        keyArgs: false,
      },
      getInvoices: {
        keyArgs(variables, context) {
          const {
            where: { galleryId, sortOrder },
          } = (variables as GetInvoicesQueryVariables) || {};
          const queryName = context.field?.alias?.value;

          return `${queryName || context.fieldName}:${JSON.stringify({ galleryId, sortOrder })}`;
        },

        merge(existing, incoming, { variables }) {
          const {
            where: { page, perPage },
          } = (variables as GetInvoicesQueryVariables) || {};

          return mergeList({ existing, incoming, page, perPage });
        },
        read(existing, { variables, storeFieldName, readField }) {
          const {
            where: { page, perPage },
          } = (variables as GetInvoicesQueryVariables) || {};

          return readList({
            readField,
            storeFieldName,
            existing,
            page,
            perPage,
            defaultOrderFields: ['displayName'],
          });
        },
      },
      getFeatureRequests: {
        keyArgs(variables, context) {
          const { where } = (variables as GetFeatureRequestsQueryVariables) || {};
          const queryName = context.field?.alias?.value;

          if (where) {
            const { orderBy, status, search } = where;

            return `${queryName || context.fieldName}:${JSON.stringify({ orderBy, status, search })}`;
          }
          return undefined;
        },

        merge(existing, incoming, { variables }) {
          const { where } = (variables as GetFeatureRequestsQueryVariables) || {};
          if (where) {
            const { page, perPage } = where;

            return mergeList({ existing, incoming, page, perPage });
          }
          return incoming;
        },

        read(existing, { variables, readField, storeFieldName }) {
          const { where } = (variables as GetFeatureRequestsQueryVariables) || {};

          if (where) {
            const { page, perPage, orderBy: orderByField, order } = where;

            return readList({
              readField,
              storeFieldName,
              existing,
              page,
              perPage,
              defaultOrderFields: orderByField ? [orderByField === 'votes' ? 'votesCount' : orderByField] : undefined,
              order: [order || 'desc'],
            });
          }
          return existing;
        },
      },
      getGalleryPresets: {
        keyArgs: (variables, context) => {
          const { where } = (variables as GetGalleryPresetsQueryVariables) || {};
          const queryName = context.field?.alias?.value;

          if (where) {
            const { order } = where;
            return `${queryName || context.fieldName}:${JSON.stringify({ order })}`;
          }

          return `${queryName || context.fieldName}`;
        },
        merge(existing, incoming, { variables }) {
          const { where } = (variables as GetGalleryPresetsQueryVariables) || {};

          return mergeList({ existing, incoming, page: where?.page, perPage: where?.perPage });
        },
        read(existing, { variables, storeFieldName, readField }) {
          const { where } = (variables as GetGalleryPresetsQueryVariables) || {};

          return readList({
            readField,
            storeFieldName,
            existing,
            page: where?.page,
            perPage: where?.perPage,
            alias: 'getPaginatedGalleryPresets',
            defaultOrderFields: ['createdAt'],
          });
        },
      },
      getAccessCodes: {
        keyArgs: (variables, context) => {
          const {
            where: { characteristics, galleryId },
          } = (variables as GetAccessCodesQueryVariables) || {};
          const queryName = context.field?.alias?.value;

          return `${queryName || context.fieldName}:${JSON.stringify({
            characteristics,
            galleryId,
          })}`;
        },
        merge(existing, incoming, { variables }) {
          const { paginate } = (variables as GetAccessCodesQueryVariables) || {};

          return mergeList({ existing, incoming, page: paginate?.page, perPage: paginate?.perPage });
        },
        read(existing, { variables, storeFieldName, readField }) {
          const { paginate } = (variables as GetAccessCodesQueryVariables) || {};

          return readList({
            readField,
            storeFieldName,
            existing,
            page: paginate?.page,
            perPage: paginate?.perPage,
            defaultOrderFields: ['createdAt'],
            order: ['asc'],
          });
        },
      },
      getClients: {
        keyArgs: (variables, context) => {
          const {
            where: { galleryId },
          } = (variables as GetClientsQueryVariables) || {};
          const queryName = context.field?.alias?.value;

          return `${queryName || context.fieldName}:${JSON.stringify({ galleryId })}`;
        },
        merge(existing, incoming, { variables }) {
          const { paginate } = (variables as GetClientsQueryVariables) || {};

          return mergeList({ existing, incoming, page: paginate?.page, perPage: paginate?.perPage });
        },
        read(existing, { variables, storeFieldName, readField }) {
          const { paginate } = (variables as GetClientsQueryVariables) || {};

          return readList({
            readField,
            storeFieldName,
            existing,
            page: paginate?.page,
            perPage: paginate?.perPage,
            defaultOrderFields: ['createdAt'],
            order: ['asc'],
          });
        },
      },
      getVisitors: {
        keyArgs: (variables, context) => {
          const {
            where: { galleryId },
          } = (variables as GetVisitorsQueryVariables) || {};
          const queryName = context.field?.alias?.value;

          return `${queryName || context.fieldName}:${JSON.stringify({ galleryId })}`;
        },
        merge(existing, incoming, { variables }) {
          const { paginate } = (variables as GetVisitorsQueryVariables) || {};

          return mergeList({ existing, incoming, page: paginate?.page, perPage: paginate?.perPage });
        },
        read(existing, { variables, storeFieldName, readField }) {
          const { paginate } = (variables as GetVisitorsQueryVariables) || {};

          return readList({
            readField,
            storeFieldName,
            existing,
            page: paginate?.page,
            perPage: paginate?.perPage,
            defaultOrderFields: ['createdAt'],
            order: ['asc'],
          });
        },
      },
      getPicklists: {
        keyArgs: (variables, context) => {
          const {
            where: { id },
          } = (variables as GetPicklistsQueryVariables) || {};
          const queryName = context.field?.alias?.value;

          return `${queryName || context.fieldName}:${JSON.stringify({ id })}`;
        },
        merge(existing, incoming, { variables }) {
          const { paginate } = (variables as GetPicklistsQueryVariables) || {};

          return mergeList({ existing, incoming, page: paginate?.page, perPage: paginate?.perPage });
        },
        read(existing, { variables, storeFieldName, readField }) {
          const { paginate } = (variables as GetPicklistsQueryVariables) || {};

          return readList({
            readField,
            storeFieldName,
            existing,
            page: paginate?.page,
            perPage: paginate?.perPage,
            defaultOrderFields: ['id'],
            order: ['asc'],
          });
        },
      },
    },
  },
};

export default new InMemoryCache({ typePolicies });
