import { createStyles } from 'antd-style';

export const useInputStyle = createStyles(({ css, prefixCls, token }) => ({
  className: css`
    &.${prefixCls}-input {
    }
    &.${prefixCls}-input-search {
      .${prefixCls}-input-outlined {
        &:focus-within {
          box-shadow: none;
        }
      }
    }
  `,
}));
