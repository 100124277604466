import React, { useCallback, useContext } from 'react';

import { useMutation, useQuery } from '@apollo/client';
import { Button, Col, List, Row } from 'antd';
import { useNavigate } from 'react-router-dom';

import Icon from 'Components/Atoms/Icon';
import Layout from 'Components/Atoms/Layout';
import StatusTag from 'Components/Atoms/StatusTag';

import FeatureRequestItem from 'Components/Molecules/FeatureRequestItem';
import Header from 'Components/Molecules/Header';

import { OnSubmitFeatureRequestPayload } from 'Forms/FeatureRequest';

import { Metrics } from 'Themes';
import styled from 'Themes/Styled';

import { LocalizationContext } from 'i18n';

import { useModals } from 'Hooks/Modal';
import { useFeatureRequestCategoriesMenu } from 'Hooks/useFeatureRequestCategoriesMenu';

import {
  FeatureOrderBy,
  FeatureStatus
} from 'Operations/__generated__/graphql';

import { GET_FEATURE_REQUESTS } from 'Operations/Queries/FeatureRequest/GetFeatureRequests';

import { CREATE_FEATURE_REQUEST } from 'Operations/Mutations/FeatureRequest/CreateFeatureRequest';

const StyledRow = styled(Row)`
  margin-top: ${Metrics.baseMargin}px;
  width: 100%;
`;

const ColContent = styled.div`
  background-color: ${({ theme }) => theme.colors.sectionContainer.background};
  border-width: 1px;
  border-style: solid;
  border-color: ${({ theme }) => theme.colors.sectionContainer.border};
  border-radius: 4px;

  padding: ${Metrics.baseMargin}px;
`;

const whereCondition = {
  perPage: 50,
  orderBy: FeatureOrderBy.VOTES,
};

const FeatureRequestsIndex = () => {
  const { t } = useContext(LocalizationContext);
  const { openModal, closeModal } = useModals();
  useFeatureRequestCategoriesMenu();
  const navigate = useNavigate();

  const { data: plannedData, loading: isLoadingPlanned } = useQuery(GET_FEATURE_REQUESTS, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-only',
    variables: {
      where: {
        ...whereCondition,
        status: FeatureStatus.PLANNED,
      },
    },
  });

  const { data: inProgressData, loading: isLoadingInProgress } = useQuery(GET_FEATURE_REQUESTS, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-only',
    variables: {
      where: {
        ...whereCondition,
        status: FeatureStatus.IN_PROGRESS,
      },
    },
  });

  const { data: liveData, loading: isLoadingLive } = useQuery(GET_FEATURE_REQUESTS, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-only',
    variables: {
      where: {
        ...whereCondition,
        status: FeatureStatus.LIVE,
      },
    },
  });

  const [createFeatureRequest] = useMutation(CREATE_FEATURE_REQUEST);

  const handleSubmit = useCallback(
    async ({ values, formikBag }: OnSubmitFeatureRequestPayload) => {
      try {
        if (!values.categoryId) {
          throw new Error('Category is required');
        }
        const res = await createFeatureRequest({
          variables: {
            data: {
              title: values.title,
              description: values.description,
              categoryId: values.categoryId,
            },
          },
        });

        formikBag.setSubmitting(false);
        closeModal('FEATURE_REQUEST');

        navigate(`/app/feature-requests/${res.data?.createFeatureRequest.id}`);
      } catch (error) {
        formikBag.setSubmitting(false);
      }
    },
    [closeModal, createFeatureRequest, navigate],
  );

  return (
    <>
      <Header
        title={t('app.common.featureRequest.table')}
        breadcrumbContent={[
          { text: t('app.menu.home'), url: '/app/dashboard' },
          { text: t('app.common.featureRequest.table') },
        ]}
        buttons={[
          <Button
            key="add"
            type="primary"
            size="large"
            onClick={() =>
              openModal('FEATURE_REQUEST', {
                onSubmit: handleSubmit,
              })
            }
            icon={<Icon name="add" />}
          >
            {t('app.common.add')}
          </Button>,
        ]}
      />
      <Layout>
        <StyledRow gutter={[16, 16]}>
          <Col xs={24} lg={8}>
            <ColContent>
              <StatusTag status={FeatureStatus.PLANNED} />
              <List
                itemLayout="vertical"
                dataSource={plannedData?.getFeatureRequests.edges || []}
                loading={isLoadingPlanned}
                renderItem={item => <FeatureRequestItem {...item} />}
              />
            </ColContent>
          </Col>
          <Col xs={24} lg={8}>
            <ColContent>
              <StatusTag status={FeatureStatus.IN_PROGRESS} />
              <List
                dataSource={inProgressData?.getFeatureRequests.edges || []}
                loading={isLoadingInProgress}
                renderItem={item => <FeatureRequestItem {...item} />}
              />
            </ColContent>
          </Col>
          <Col xs={24} lg={8}>
            <ColContent>
              <StatusTag status={FeatureStatus.LIVE} />
              <List
                dataSource={liveData?.getFeatureRequests.edges || []}
                loading={isLoadingLive}
                renderItem={item => <FeatureRequestItem {...item} />}
              />
            </ColContent>
          </Col>
        </StyledRow>
      </Layout>
    </>
  );
};

export default FeatureRequestsIndex;
