import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';

import { ApolloCache, useMutation, useQuery } from '@apollo/client';
import { App, Button, Empty, Tabs } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';

import { chain, kebabCase } from 'lodash';

import Container from 'Components/Atoms/Container';
import ContentContainer from 'Components/Atoms/ContentContainer';
import Icon from 'Components/Atoms/Icon';
import Layout from 'Components/Atoms/Layout';

import CatalogProductsDrawer from 'Components/Molecules/Drawers/CatalogProductsDrawer';
import Header from 'Components/Molecules/Header';
import StatCard from 'Components/Molecules/Stats/StatCard';
import ProductsTable from 'Components/Molecules/Tables/ProductsTable';

import { EditCatalogFormPayload } from 'Forms/Catalog';

import { Colors, Metrics } from 'Themes';
import styled from 'Themes/Styled';

import { LocalizationContext } from 'i18n';

import { useModals } from 'Hooks/Modal';

import {
  GetCatalogProductsQuery,
  GetCatalogProductsQueryVariables,
  GetProductsQuery
} from 'Operations/__generated__/graphql';

import { GET_CATALOG } from 'Operations/Queries/Catalog/GetCatalog';
import { GET_CATALOG_PRODUCTS } from 'Operations/Queries/Catalog/GetCatalogProducts';

import { ADD_PRODUCTS_TO_CATALOG } from 'Operations/Mutations/Catalog/AddProductsToCatalog';
import { REMOVE_PRODUCTS_TO_CATALOG } from 'Operations/Mutations/Catalog/removeProductsFromCatalog';
import { UPDATE_CATALOG } from 'Operations/Mutations/Catalog/UpdateCatalog';

const { TabPane: TabPaneAnt } = Tabs;

const RowContainer = styled(Container)`
  width: 100%;
  padding: ${Metrics.baseMargin}px 0;
  gap: ${Metrics.baseMargin}px;
`;

const StyledTabsContainer = styled(ContentContainer)`
  flex: 1;
  width: 100%;
  padding-top: ${Metrics.smallMargin}px;
`;

const StyledTabs = styled(Tabs)`
  flex: 1;
`;

const TabPane = styled(TabPaneAnt)`
  padding-top: ${Metrics.smallMargin}px;
  padding-bottom: ${Metrics.smallMargin}px;
`;

const statCardProps: {
  minWidth: number;
  shadow: boolean;
  width: 'auto';
} = {
  minWidth: 90,
  shadow: true,
  width: 'auto',
};

const PER_PAGE = 20;

const CatalogShow = () => {
  const { t } = useContext(LocalizationContext);
  const { openModal, closeModal } = useModals();
  const { id, category: defaultCategoryName } = useParams<{ id: string; category?: string }>();
  const { message } = App.useApp();
  const navigate = useNavigate();
  const [isDrawerVisible, setIsDrawerVisible] = useState(false);
  const [activeKey, setActiveKey] = useState('');

  const [productsQueryParams, setProductsQueryParams] = useState<GetCatalogProductsQueryVariables['productsWhere']>({
    page: 1,
    perPage: PER_PAGE,
  });

  const catalogId = id ? parseInt(id, 10) : undefined;

  const { data: dataCatalog } = useQuery(GET_CATALOG, {
    skip: !catalogId,
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-only',
    variables: {
      where: {
        id: catalogId as number,
      },
    },
  });

  const refetchQueries = [
    {
      query: GET_CATALOG,
      variables: {
        where: {
          id: catalogId as number,
        },
      },
    },
  ];

  const [updateCatalog] = useMutation(UPDATE_CATALOG);

  const [removeProductsFromCatalog] = useMutation(REMOVE_PRODUCTS_TO_CATALOG, {
    refetchQueries,
  });

  const [addProductsToCatalog] = useMutation(ADD_PRODUCTS_TO_CATALOG, {
    refetchQueries,
  });

  const catalog = useMemo(() => dataCatalog?.getCatalog, [dataCatalog?.getCatalog]);

  const defaultCategory = useMemo(
    () =>
      catalog?.productCategories.find(({ name }) => defaultCategoryName === kebabCase(name)) ||
      catalog?.productCategories?.[0],
    [catalog?.productCategories, defaultCategoryName],
  );

  const variables: GetCatalogProductsQueryVariables = useMemo(
    () => ({
      where: {
        id: catalogId as number,
      },
      productsWhere: {
        categoryId: defaultCategory?.id,
        ...productsQueryParams,
      },
    }),
    [catalogId, defaultCategory, productsQueryParams],
  );

  const {
    data: dataCatalogProducts,
    loading: isProductsLoading,
    fetchMore: fetchMoreProducts,
  } = useQuery(GET_CATALOG_PRODUCTS, {
    skip: !catalogId || !variables.productsWhere?.categoryId,
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-only',
    variables,
  });

  useEffect(() => {
    const category = catalog?.productCategories.find(category => category.id === parseInt(activeKey, 10));

    if (category) {
      navigate(`/app/catalogs/${id}/${kebabCase(category.name)}`);
    }
  }, [activeKey, catalog?.productCategories, navigate, id]);

  useEffect(() => {
    if (defaultCategory) {
      setActiveKey(defaultCategory.id.toString());
    }
  }, [defaultCategory]);

  const handleUpdateSubmit = useCallback(
    async ({ values: { id, ...data }, formikBag }: EditCatalogFormPayload) => {
      try {
        await updateCatalog({
          variables: {
            where: {
              id,
            },
            data,
          },
        });

        message.success(t('app.message.catalog.edit.success'));
        formikBag.setSubmitting(false);

        closeModal();
      } catch (error) {
        console.log(error);

        message.error(t('app.message.error.somethingWentWrong'));
        formikBag.setSubmitting(false);
      }
    },
    [closeModal, t, updateCatalog],
  );

  const updateCachedProducts = useCallback(
    ({
      cache,
      updater,
    }: {
      cache: ApolloCache<unknown>;
      updater: (cachedData: GetCatalogProductsQuery['getCatalog']) => GetCatalogProductsQuery['getCatalog'];
    }) => {
      if (catalogId) {
        const result = cache.readQuery({
          query: GET_CATALOG_PRODUCTS,
          variables,
        });

        const { getCatalog } = result || {
          getCatalog: {
            id: catalogId,
            products: { __typename: 'ProductConnection', _count: 0, edges: [] },
            favoriteProducts: {
              _count: 0,
              _maxFavorites: 0,
              edges: [],
              __typename: 'FavoriteProductConnection',
            },
            __typename: 'Catalog',
          },
        };

        cache.writeQuery({
          query: GET_CATALOG_PRODUCTS,
          variables,
          data: {
            getCatalog: {
              ...getCatalog,
              ...updater(getCatalog),
            },
          },
        });
      }
    },
    [catalogId, variables],
  );

  const handleRemoveProduct = useCallback(
    async ({ ids }: { ids: number[] }) => {
      if (catalogId) {
        await removeProductsFromCatalog({
          variables: {
            where: {
              id: catalogId,
            },
            data: {
              ids,
            },
          },
          update(cache) {
            if (catalogId) {
              updateCachedProducts({
                cache,
                updater(cachedData) {
                  const favoriteProducts = cachedData.favoriteProducts.edges.filter(p => ids.every(id => id !== p.id));
                  return {
                    ...cachedData,
                    favoriteProducts: {
                      ...cachedData.favoriteProducts,
                      edges: favoriteProducts,
                      _count: favoriteProducts.length,
                    },
                    products: {
                      ...cachedData.products,
                      _count: cachedData.products._count - ids.length,
                      edges: cachedData.products.edges.filter(product => !ids.includes(product.id)),
                    },
                  };
                },
              });
            }
          },
        });
      }
    },
    [catalogId, removeProductsFromCatalog, updateCachedProducts],
  );

  const handleAddProduct = useCallback(
    async (products: GetProductsQuery['getProducts']['edges']) => {
      if (catalogId) {
        await addProductsToCatalog({
          variables: {
            where: {
              id: catalogId,
            },
            data: {
              ids: products.map(p => p.id),
            },
          },
          update(cache) {
            if (catalogId) {
              updateCachedProducts({
                cache,
                updater: cachedData => {
                  const categoryProducts = products.filter(p => p.category.id === variables.productsWhere?.categoryId);

                  return {
                    ...cachedData,
                    products: {
                      ...cachedData.products,
                      edges: chain(cachedData.products.edges).concat(categoryProducts).uniqBy('id').value(),
                      _count: cachedData.products._count + categoryProducts.length,
                    },
                  };
                },
              });
            }
          },
        });
      }
    },
    [addProductsToCatalog, catalogId, updateCachedProducts, variables.productsWhere?.categoryId],
  );

  const handleOpenDrawer = useCallback(() => {
    setIsDrawerVisible(true);
  }, []);

  const handlePaginationChange = useCallback(
    ({ page }: { page: number }) => {
      if (catalogId) {
        const params = { ...productsQueryParams, page };
        fetchMoreProducts?.({
          variables: {
            where: {
              id: catalogId,
            },
            productsWhere: params,
          },
        });
        setProductsQueryParams(params);
      }
    },
    [catalogId, fetchMoreProducts, productsQueryParams],
  );

  if (!catalog) {
    return null;
  }

  return (
    <>
      <Header
        extra={catalog.isDefault ? <Icon name="star" color={Colors.secondaryOptional} size={24} /> : null}
        title={catalog.name || '-'}
        breadcrumbContent={[
          { text: t('app.menu.home'), url: '/app/dashboard' },
          { text: t('app.common.catalog', { count: 2 }), url: '/app/catalogs' },
          { text: catalog.name || '-' },
        ]}
        buttons={[
          <Button
            key="edit"
            size="large"
            icon={<Icon name="edit" />}
            onClick={() => {
              if (catalog) {
                openModal('CATALOG', {
                  name: 'edit',
                  onSubmit: handleUpdateSubmit,
                  defaultValues: {
                    id: catalog.id,
                    name: catalog.name || '',
                    isDefault: !!catalog?.isDefault,
                    vat: catalog?.vat,
                  },
                });
              }
            }}
          >
            {t('app.common.edit')}
          </Button>,
        ]}
      />
      <Layout flexDirection="column">
        <Container flex={1} direction="column">
          <RowContainer>
            <StatCard
              {...statCardProps}
              label={t('app.common.products', { count: catalog.products._count })}
              value={catalog.products._count}
            />
            <StatCard
              {...statCardProps}
              label={t('app.product.favorite')}
              value={
                dataCatalogProducts
                  ? dataCatalogProducts.getCatalog.favoriteProducts._count +
                    '/' +
                    dataCatalogProducts.getCatalog.favoriteProducts._maxFavorites
                  : '-'
              }
            />
          </RowContainer>
          <StyledTabsContainer shadow rounded padding="baseMargin">
            {catalog.productCategories.length === 0 ? (
              <Container direction="column" align="center" justify="center">
                <Empty />
                <Button type="primary" size="small" onClick={handleOpenDrawer}>
                  {t('app.products.add', { count: 1 })}
                </Button>
              </Container>
            ) : (
              <StyledTabs
                activeKey={activeKey}
                onChange={categoryId => setActiveKey(categoryId)}
                tabBarExtraContent={
                  <Button type="primary" size="small" onClick={handleOpenDrawer}>
                    {t('app.products.add', { count: 1 })}
                  </Button>
                }
              >
                {catalog.productCategories.map(category => (
                  <TabPane tab={category.name} key={category.id}>
                    <ProductsTable
                      products={dataCatalogProducts?.getCatalog.products.edges || []}
                      catalogId={catalogId}
                      favoriteProducts={dataCatalogProducts?.getCatalog.favoriteProducts}
                      paginationSize={PER_PAGE}
                      onChange={handlePaginationChange}
                      isLoading={isProductsLoading}
                      onDelete={handleRemoveProduct}
                      total={dataCatalogProducts?.getCatalog.products._count || 0}
                    />
                  </TabPane>
                ))}
              </StyledTabs>
            )}
          </StyledTabsContainer>
        </Container>
      </Layout>
      {catalogId && (
        <CatalogProductsDrawer
          isVisible={isDrawerVisible}
          category={defaultCategory && defaultCategory?.isDigital ? undefined : defaultCategory}
          onCloseDrawer={() => setIsDrawerVisible(false)}
          onProductsAdded={handleAddProduct}
          catalogId={catalogId}
          hideDigitalProducts={false}
        />
      )}
    </>
  );
};

export default CatalogShow;
