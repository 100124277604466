import React, {
  createContext,
  ReactNode,
  useCallback,
  useMemo,
  useState
} from 'react';

import { App, ConfigProvider, ThemeConfig } from 'antd';
import { CustomToken, ThemeProvider as AntThemeProvider } from 'antd-style';
import deDE from 'antd/lib/locale/de_DE';
import enGB from 'antd/lib/locale/en_GB';
import esES from 'antd/lib/locale/es_ES';
import frFR from 'antd/lib/locale/fr_FR';
import itIT from 'antd/lib/locale/it_IT';
import nlNL from 'antd/lib/locale/nl_NL';

import { merge } from 'lodash';

import { ThemeConfig as AppThemeConfig } from 'Themes';
import {
  customStylish,
  customToken,
  theme,
  useButtonStyle,
  useCardStyle,
  useDrawerStyle,
  useFlexStyle,
  useFormStyle,
  useInputStyle,
  useModalStyle,
  useNotificationStyle,
  usePopConfirmStyle,
  useRadioStyle,
  useReactModalStyle,
  useStepsStyle,
  useTableStyle,
  useTreeStyle
} from 'Themes/styles';

import { ThemeProvider as StyledThemeProvider } from 'styled-components';

interface Props {
  locale: string;
  children?: ReactNode;
}

export const ThemeContext = createContext<{
  theme: ThemeConfig | undefined;
  customToken: CustomToken;
  setTheme: (data: { theme?: Partial<ThemeConfig>; customToken?: Partial<CustomToken> }) => void;
}>({
  theme: theme(''),
  customToken,
  setTheme: () => null,
});

const defaultTheme = { theme: theme(''), customToken, customStylish };

const ThemeProvider = (props: Props) => {
  const [themeConfig, setThemeConfig] = useState(defaultTheme);

  const setTheme = useCallback(
    (data: { theme?: Partial<ThemeConfig>; customToken?: Partial<CustomToken> }) => {
      setThemeConfig(merge({}, defaultTheme, data));
    },
    [setThemeConfig],
  );

  return (
    <ThemeContext.Provider value={{ ...themeConfig, setTheme }}>
      <AntThemeProvider
        theme={themeConfig.theme}
        customToken={themeConfig.customToken}
        customStylish={themeConfig.customStylish}
      >
        <Content {...props} />
      </AntThemeProvider>
    </ThemeContext.Provider>
  );
};
const Content = ({ locale, children }: Props) => {
  const button = useButtonStyle();
  const tree = useTreeStyle();
  const card = useCardStyle();
  const modal = useModalStyle();
  const form = useFormStyle();
  const table = useTableStyle();
  const notification = useNotificationStyle();
  const steps = useStepsStyle();
  const radio = useRadioStyle();
  const flex = useFlexStyle();
  const input = useInputStyle();
  const { GlobalModalClass } = useReactModalStyle();
  const { GlobalPopconfirmClass } = usePopConfirmStyle();
  const { GlobalDrawerClass, ...drawer } = useDrawerStyle();

  const antLocale = useMemo(() => {
    switch (locale) {
      case 'fr':
        return frFR;
      case 'es':
        return esES;
      case 'it':
        return itIT;
      case 'nl':
        return nlNL;
      case 'de':
        return deDE;
      case 'en':
      default:
        return enGB;
    }
  }, [locale]);

  return (
    <StyledThemeProvider theme={AppThemeConfig[0]}>
      <ConfigProvider
        locale={antLocale}
        card={{ styles: {}, ...card.styles }}
        button={button.styles}
        tree={tree.styles}
        steps={steps.styles}
        modal={modal.styles}
        table={table.styles}
        radio={radio.styles}
        notification={notification.styles}
        drawer={drawer}
        form={form.styles}
        flex={flex.styles}
        input={input.styles}
      >
        <App>
          <GlobalDrawerClass />
          <GlobalModalClass />
          <GlobalPopconfirmClass />
          {children}
        </App>
      </ConfigProvider>
    </StyledThemeProvider>
  );
};

export default ThemeProvider;
