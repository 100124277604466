import { useCallback, useContext } from 'react';

import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { Alert, App, Button } from 'antd';
import { useNavigate } from 'react-router-dom';

import ContentContainer from 'Components/Atoms/ContentContainer';
import Icon from 'Components/Atoms/Icon';
import Layout from 'Components/Atoms/Layout';

import Header from 'Components/Molecules/Header';
import ProductsTable, { OnChangeProps } from 'Components/Molecules/Tables/FotostudioProductsTable';

import { NewProductFormPayload } from 'Forms/Product';

import { Metrics } from 'Themes';
import styled from 'Themes/Styled';

import { LocalizationContext } from 'i18n';

import { useModals } from 'Hooks/Modal';
import { useRoles } from 'Hooks/useRoles';

import {
  Labo,
  PricingPlanType,
  ProductCharacteristic
} from 'Operations/__generated__/graphql';

import { GET_FOTOSTUDIO_PRODUCTS } from 'Operations/Queries/Product/GetFotostudioProducts';
import { GET_PRODUCT } from 'Operations/Queries/Product/GetProduct';
import { GET_PRODUCT_FILTERS } from 'Operations/Queries/Product/GetProductFilters';

import { CREATE_PRODUCT } from 'Operations/Mutations/Product/CreateProduct';

const ProductsLayout = styled(ContentContainer)`
  width: 100%;
  flex: 1;
  align-self: flex-start;
  margin-top: ${Metrics.baseMargin}px;
`;

const CreateButton = styled(Button)`
  display: flex;
  align-items: center;
`;

const AlertStyled = styled(Alert)`
  margin-top: ${Metrics.baseMargin}px;
`;

const PER_PAGE = 30;

const ProductsIndex = () => {
  const { t } = useContext(LocalizationContext);
  const { message } = App.useApp();
  const navigate = useNavigate();
  const { openModal, closeModal } = useModals();
  const { isAdmin } = useRoles();

  const {
    data,
    loading: isLoading,
    fetchMore,
  } = useQuery(GET_FOTOSTUDIO_PRODUCTS, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-only',
    variables: { where: { page: 1, perPage: PER_PAGE, characteristics: [ProductCharacteristic.FOTOSTUDIO] } },
  });

  const { data: filtersData } = useQuery(GET_PRODUCT_FILTERS, {
    fetchPolicy: 'cache-and-network',
    variables: {
      where: {
        characteristics: [ProductCharacteristic.FOTOSTUDIO],
      },
    },
  });

  const [getProduct] = useLazyQuery(GET_PRODUCT, {
    fetchPolicy: 'network-only',
    onCompleted: productData => {
      if (productData.getProduct) {
        const { __typename, pricingPlanMeta, ...product } = productData.getProduct;

        const formValues = (({
          id,
          previewAsset,
          photoSizes,
          name,
          contactId,
          maxPhotoCount,
          description,
          weight,
        }) => ({
          categoryId: undefined,
          previewImage: undefined,
          previewImageUrl: previewAsset?.downloadUrl,
          orderedByFotostudio: false,
          isPreconfiguredProduct: false,
          isFotostudioProduct: true,
          enableCrop: photoSizes.length > 0,
          photoSizes: photoSizes.map(({ __typename, ...sizes }) => sizes),
          presetProductId: id,
          isDigital: false,
          profitMarginPercent: undefined,
          name,
          contactId,
          maxPhotoCount,
          description,
          weight,
        }))(product);

        openModal('PRODUCT', {
          name: 'new',
          onSubmit: handleCreateOnSubmit,
          defaultValues: {
            name: '',
            type: PricingPlanType.UNITARY,
            vat: undefined,
            items: [
              {
                quantity: 1,
                price: 20,
                additionalPrice: null,
              },
            ],
            // TODO: Fix types
            // @ts-ignore
            product: formValues,
          },
          isAdmin: false,
          minPrice: pricingPlanMeta?.startingPrice ?? undefined,
        });
      }
    },
  });

  const [createProduct] = useMutation(CREATE_PRODUCT);

  const handleCreateOnSubmit = useCallback(
    async ({
      values: {
        product: {
          previewImageUrl: _previewImageUrl,
          enableCrop: _enableCrop,
          isDigital: _isDigital,
          withNewPricingPlan: _withNewPricingPlan,
          ...productData
        },
      },
      formikBag,
    }: NewProductFormPayload) => {
      try {
        const product = await createProduct({ variables: { data: { ...productData, labo: Labo.INCOGNITO } } });

        message.success(t('app.message.product.create.success'));
        formikBag.setSubmitting(false);
        closeModal();

        if (product.data?.createProduct) {
          navigate(`/app/${productData.presetProductId ? '' : 'fotostudio-'}products/${product.data.createProduct.id}`);
        }
      } catch (error) {
        console.log(error);

        message.error(t('app.message.error.somethingWentWrong'));
        formikBag.setSubmitting(false);
      }
    },
    [closeModal, createProduct, navigate, t],
  );

  const handleOnAddClick = useCallback(
    ({ id }: { id: number }) => {
      getProduct({
        variables: {
          where: { id },
        },
      });
    },
    [getProduct],
  );

  const handleOnCreateClick = useCallback(() => {
    openModal('PRODUCT', {
      name: 'new',
      onSubmit: handleCreateOnSubmit,
      defaultValues: {
        type: PricingPlanType.UNITARY,
        items: [
          {
            quantity: 1,
            price: 20,
            additionalPrice: null,
          },
        ],
        product: {
          name: '',
          previewImage: undefined,
          previewImageUrl: undefined,
          categoryId: undefined as unknown as number,
          contactId: undefined as unknown as number,
          description: '',
          maxPhotoCount: 1,
          photoSizes: [
            {
              width: undefined as unknown as number,
              height: undefined as unknown as number,
            },
          ],
          orderedByFotostudio: false,
          isPreconfiguredProduct: false,
          isFotostudioProduct: true,
          enableCrop: true,
          presetProductId: undefined,
          isDigital: false,
        },
        permitAdditionalItems: false,
        withWholeGalleryPrice: false,
      },
      isAdmin,
    });
  }, [handleCreateOnSubmit, isAdmin, openModal]);

  const handleChange = useCallback(
    ({ page, categoryIds, contactIds }: OnChangeProps) => {
      if (fetchMore) {
        fetchMore({
          variables: {
            where: {
              page,
              perPage: PER_PAGE,
              categoryIds,
              contactIds,
              characteristics: [ProductCharacteristic.FOTOSTUDIO],
            },
          },
        });
      }
    },
    [fetchMore],
  );

  const headerButtons = [];

  if (isAdmin) {
    headerButtons.push(
      <CreateButton
        onClick={handleOnCreateClick}
        key="add"
        size="large"
        type="primary"
        icon={<Icon name="add" size={18} />}
      >
        {t('app.common.add')}
      </CreateButton>,
    );
  }

  return (
    <>
      <Header
        title={t('app.common.fotostudioProducts')}
        breadcrumbContent={[
          { text: t('app.menu.home'), url: '/app/dashboard' },
          { text: t('app.common.fotostudioProducts') },
        ]}
        buttons={headerButtons}
      />
      <Layout flexDirection="column">
        <AlertStyled type="info" message={t('app.products.fotostudio.info.text')} />
        <ProductsLayout>
          <ProductsTable
            products={data?.getFotostudioProducts.edges || []}
            filters={filtersData?.getProductFilters}
            paginationSize={PER_PAGE}
            onChange={handleChange}
            isLoading={isLoading}
            onAdd={handleOnAddClick}
            total={data?.getFotostudioProducts._count || 0}
          />
        </ProductsLayout>
      </Layout>
    </>
  );
};

export default ProductsIndex;
