import React from 'react';

import { Flex, Space } from 'antd';
import { createStyles } from 'antd-style';

import Title from 'Components/Atoms/Title';

import Breadcrumb, { BreadcrumbContent } from 'Components/Molecules/Breadcrumb';

const useStyles = createStyles(({ css, token }) => ({
  container: css`
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid ${token.colorBorder};
    padding-bottom: ${token.sizeSM}px;
  `,
}));

interface Props {
  title: string | JSX.Element;
  extra?: JSX.Element | null;
  buttons?: JSX.Element[];
  breadcrumbContent?: BreadcrumbContent[];
}

const Header = ({ title, buttons, breadcrumbContent, extra = null }: Props) => {
  const { styles, theme } = useStyles();

  return (
    <header className={styles.container}>
      <Flex vertical gap="small">
        <Space>
          {extra}
          <Title level="h2" color={theme?.colorText}>
            {title}
          </Title>
        </Space>
        {breadcrumbContent && <Breadcrumb content={breadcrumbContent} />}
      </Flex>
      {buttons && (
        <Flex align="center" gap="small">
          {buttons}
        </Flex>
      )}
    </header>
  );
};

export default Header;
