import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';

import { useLazyQuery, useMutation } from '@apollo/client';
import { App, Button } from 'antd';

import ContentContainer from 'Components/Atoms/ContentContainer';
import Icon from 'Components/Atoms/Icon';
import Layout from 'Components/Atoms/Layout';

import Header from 'Components/Molecules/Header';
import EmailTemplateTable, { handleOnChangeProps } from 'Components/Molecules/Tables/EmailTemplateTable';

import {
  EditEmailTemplatePayload,
  NewEmailTemplatePayload
} from 'Forms/EmailTemplate';

import { Metrics } from 'Themes';
import styled from 'Themes/Styled';

import { LocalizationContext } from 'i18n';

import { useModals } from 'Hooks/Modal';

import {
  EmailTemplateAssociatedModel,
  EmailTemplateOrderType
} from 'Operations/__generated__/graphql';

import { GET_EMAIL_TEMPLATE } from 'Operations/Queries/EmailTemplate/GetEmailTemplate';
import { GET_PAGINATED_EMAIL_TEMPLATES } from 'Operations/Queries/EmailTemplate/GetPaginatedEmailTemplates';

import { CREATE_EMAIL_TEMPLATE } from 'Operations/Mutations/EmailTemplate/CreateEmailTemplate';
import { DELETE_EMAIL_TEMPLATE } from 'Operations/Mutations/EmailTemplate/DeleteEmailTemplate';
import { UPDATE_EMAIL_TEMPLATE } from 'Operations/Mutations/EmailTemplate/UpdateEmailTemplate';

const PER_PAGE = 20;

const StyledTableContentContainer = styled(ContentContainer)`
  flex: 1;
  align-self: flex-start;
  margin-top: ${Metrics.baseMargin}px;
`;

const EmailTemplatesIndex = () => {
  const { t } = useContext(LocalizationContext);
  const { message } = App.useApp();
  const { openModal, closeModal } = useModals();

  const [paginationParams, setPaginationParams] = useState({
    page: 1,
    perPage: PER_PAGE,
    order: EmailTemplateOrderType.DESC,
    associatedModel: [
      EmailTemplateAssociatedModel.CUSTOM,
      EmailTemplateAssociatedModel.GALLERY,
      EmailTemplateAssociatedModel.GALLERYORDERAVAILABLE,
      EmailTemplateAssociatedModel.GALLERYINVOICE,
      EmailTemplateAssociatedModel.GALLERYACCESSCODE,
    ],
  });

  const [getEmailTemplates, { data, loading: isTemplatesLoading }] = useLazyQuery(GET_PAGINATED_EMAIL_TEMPLATES, {
    fetchPolicy: 'cache-and-network',
  });

  const [getEmailTemplate] = useLazyQuery(GET_EMAIL_TEMPLATE, {
    fetchPolicy: 'network-only',
    onCompleted: data => {
      if (data.getEmailTemplate) {
        const { __typename, ...emailTemplate } = data.getEmailTemplate;

        const formValues = (({ id, name, title, content, attachmentName, attachmentUrl }) => ({
          id,
          name,
          title,
          content,
          attachmentName,
          attachmentUrl,
        }))(emailTemplate);

        openModal('EMAIL_TEMPLATE', {
          name: 'edit',
          onSubmit: handleUpdateOnSubmit,
          defaultValues: formValues,
          associatedModel: emailTemplate.associatedModel,
        });
      }
    },
  });

  const refetchQueries = useMemo(
    () => [{ query: GET_PAGINATED_EMAIL_TEMPLATES, variables: { where: paginationParams } }],
    [paginationParams],
  );

  const [createEmailTemplate] = useMutation(CREATE_EMAIL_TEMPLATE, {
    refetchQueries,
  });

  const [updateEmailTemplate] = useMutation(UPDATE_EMAIL_TEMPLATE);

  const [deleteEmailTemplate] = useMutation(DELETE_EMAIL_TEMPLATE, {
    refetchQueries,
    awaitRefetchQueries: true,
    update(cache, { data }) {
      if (data && data.deleteEmailTemplate) {
        const normalizedId = cache.identify({
          id: data.deleteEmailTemplate.id,
          __typename: 'EmailTemplate',
        });
        cache.evict({ id: normalizedId });
        cache.gc();
      }
    },
    onCompleted: () => {
      if (data && data?.getPaginatedEmailTemplates?._count % PER_PAGE === 0 && paginationParams.page > 1) {
        setPaginationParams({ ...paginationParams, page: paginationParams.page - 1 });
      }
    },
  });

  useEffect(() => {
    getEmailTemplates({
      variables: {
        where: paginationParams,
      },
    });
  }, [getEmailTemplates, paginationParams]);

  const handleOnSubmit = useCallback(
    async ({
      values: { attachmentName: _attachName, attachmentUrl: _attachUrl, ...values },
      formikBag,
    }: NewEmailTemplatePayload) => {
      try {
        await createEmailTemplate({ variables: { data: values } });

        message.success(t('app.message.emailTemplate.create.success'));
        formikBag.setSubmitting(false);

        closeModal();
      } catch (error) {
        console.log(error);

        message.error(t('app.message.error.somethingWentWrong'));
        formikBag.setSubmitting(false);
      }
    },
    [createEmailTemplate, closeModal, t],
  );

  const handleUpdateOnSubmit = useCallback(
    async ({
      values: { id, attachmentName: _attachName, attachmentUrl: _attachUrl, ...values },
      formikBag,
    }: EditEmailTemplatePayload) => {
      try {
        await updateEmailTemplate({ variables: { where: { id }, data: values } });

        message.success(t('app.message.emailTemplate.update.success'));
        formikBag.setSubmitting(false);

        closeModal();
      } catch (error) {
        console.log(error);

        message.error(t('app.message.error.somethingWentWrong'));
        formikBag.setSubmitting(false);
      }
    },
    [closeModal, t, updateEmailTemplate],
  );

  const handleFilterTable = useCallback(
    async (params: handleOnChangeProps) => setPaginationParams(oldParams => ({ ...oldParams, ...params })),
    [],
  );

  const handleEditClick = ({ id }: { id: number }) => {
    try {
      getEmailTemplate({
        variables: {
          where: {
            id,
          },
        },
      });
    } catch (error) {
      console.log(error);

      message.error(t('app.message.error.somethingWentWrong'));
    }
  };

  const handleDeleteConfirm = useCallback(
    ({ id }: { id: number }) => {
      try {
        deleteEmailTemplate({
          variables: {
            where: {
              id,
            },
          },
        });

        message.success(t('app.message.emailTemplate.delete.success'));
      } catch (error) {
        console.log(error);

        message.error(t('app.message.error.somethingWentWrong'));
      }
    },
    [deleteEmailTemplate, t],
  );

  return (
    <>
      <Header
        title={t('app.emailTemplate.manage')}
        breadcrumbContent={[
          { text: t('app.menu.home'), url: '/app/dashboard' },
          { text: t('app.common.emailTemplates', { count: 2 }) },
        ]}
        buttons={[
          <Button
            key="add"
            type="primary"
            size="large"
            onClick={() =>
              openModal('EMAIL_TEMPLATE', {
                name: 'new',
                onSubmit: handleOnSubmit,
              })
            }
            icon={<Icon name="add" />}
          >
            {t('app.common.add')}
          </Button>,
        ]}
      />
      <Layout>
        <StyledTableContentContainer>
          <EmailTemplateTable
            emailTemplatesTotal={data?.getPaginatedEmailTemplates ? data?.getPaginatedEmailTemplates._count : 0}
            data={data?.getPaginatedEmailTemplates.edges}
            loading={isTemplatesLoading}
            paginationCurrent={paginationParams.page}
            paginationSize={paginationParams.perPage}
            handleOnChange={handleFilterTable}
            handleEditClick={handleEditClick}
            handleDeleteConfirm={handleDeleteConfirm}
          />
        </StyledTableContentContainer>
      </Layout>
    </>
  );
};

export default EmailTemplatesIndex;
