import React from 'react';

import { Card } from 'antd';

import Container from 'Components/Atoms/Container';
import Text from 'Components/Atoms/Text';

import styled from 'Themes/Styled';

const CardContainer = styled(Card)`
  max-width: 180px;
`;

interface DeliveryInfoCardProps {
  label: string;
  title?: string;
  content?: string;
}

const DeliveryInfoCard = ({ label, title, content }: DeliveryInfoCardProps) => {
  return (
    <CardContainer bordered={false}>
      <Container direction="column" align="center">
        <Text size="small">{label}</Text>
        {title && <Text weight="bold">{title}</Text>}
        {content && (
          <Text size="small" weight="bold" align="center">
            {content}
          </Text>
        )}
      </Container>
    </CardContainer>
  );
};

export default DeliveryInfoCard;
