import { useCallback, useContext, useEffect, useRef } from 'react';

import { useQuery } from '@apollo/client';
import { Button, Divider, Flex, Popconfirm } from 'antd';
import { createStyles } from 'antd-style';

import { FormikBag, FormikProps, withFormik } from 'formik';
import { Form, Input } from 'formik-antd';
import { trim } from 'lodash';

import { LocalizationContext } from 'i18n';

import yup from 'Services/YupService';

export interface NewGallerySpotifyUriFormValues {
  spotifyCode?: string | null;
  spotifyUri?: string | null;
}

export interface EditGallerySpotifyUriFormValues extends NewGallerySpotifyUriFormValues {
  id: number;
}

const useStyles = createStyles(({ css, token }) => ({
  spotifyContainer: css`
    flex: 1;
    margin-top: ${token.sizeLG}px;
  `,
  spotifyPreview: css`
    gap: ${token.sizeLG}px;
  `,
}));

const GallerySpotifyUriForm = <T extends EditGallerySpotifyUriFormValues>({
  isSubmitting,
  values,
  initialValues,
  errors,
  setFieldValue,
  setFieldError,
  submitForm,
}: FormikProps<T>) => {
  const { t } = useContext(LocalizationContext);
  const { styles, cx } = useStyles();

  const initSpotifyContainer = useCallback(() => {
    const spotifyIframeContainer = document.getElementById('spotify-iframe-container');

    if (spotifyIframeContainer) {
      spotifyIframeContainer.innerHTML = '<div id="spotify-iframe"></div>';
    }
  }, []);

  const handleReset = useCallback(() => {
    setFieldValue('spotifyCode', null);
    setFieldValue('spotifyUri', null);

    initSpotifyContainer();

    submitForm();
  }, [setFieldValue, initSpotifyContainer]);

  useEffect(() => {
    if (!!values.spotifyUri) {
      initSpotifyContainer();

      window.onSpotifyIframeApiReady = (IFrameAPI: any) => {
        const element = document.getElementById('spotify-iframe');
        const options = {
          theme: 'dark',
          width: '100%',
          height: 152,
          uri: `spotify:${values.spotifyUri}`,
        };

        const callback = (EmbedController: any) => {};
        IFrameAPI.createController(element, options, callback);
      };
    }
  }, [values.spotifyUri, initSpotifyContainer]);

  useEffect(() => {
    if (values.spotifyCode) {
      const spotifyCode = trim(values.spotifyCode);

      if (!!spotifyCode) {
        // Retrieve in spotifyCode the part of the string between 'open.spotify.com/embed/' and '?utm_source=generator'
        // Then replace the / in spotifyUri with a :
        let spotifyUri = spotifyCode;

        if (spotifyCode.includes('open.spotify.com/')) {
          spotifyUri = spotifyUri.split('open.spotify.com/')[1];
        }

        if (spotifyCode.includes('embed/')) {
          spotifyUri = spotifyUri.split('embed/')[1];
        }

        if (spotifyCode.includes('?')) {
          spotifyUri = spotifyUri.split('?')[0];
        }

        spotifyUri = spotifyUri.replace(/\//g, ':');

        // Validate that uri contains 'track:' or 'album:' or 'playlist:'.
        if (
          spotifyUri.includes('track:') ||
          spotifyUri.includes('album:') ||
          spotifyUri.includes('playlist:') ||
          spotifyUri.includes('artist:')
        ) {
          setFieldValue('spotifyUri', spotifyUri);
        }
      }
    }
  }, [setFieldValue, setFieldError, values.spotifyCode]);

  return (
    <Form layout="vertical">
      <Flex className={cx(!!values.spotifyUri && styles.spotifyPreview)}>
        <Form.Item label={t('app.gallery.spotifyCode.label')} name="spotifyCode" required hasFeedback={false}>
          <Input.TextArea name="spotifyCode" autoSize={{ minRows: 6 }} />
        </Form.Item>
        {!!values.spotifyUri && (
          <div className={styles.spotifyContainer} id="spotify-iframe-container">
            <div id="spotify-iframe"></div>
          </div>
        )}
      </Flex>
      <Flex justify="flex-end" gap="small">
        {!!initialValues.spotifyUri && !!values.spotifyUri && (
          <>
            <Popconfirm title={t(`app.gallery.spotify.confirmReset`)} onConfirm={() => handleReset()}>
              <Button htmlType="button" type="default" size="large">
                {t('app.common.reset')}
              </Button>
            </Popconfirm>
            <Divider type="vertical" style={{ height: 'auto' }} />
          </>
        )}
        <Button htmlType="submit" type="primary" size="large" loading={isSubmitting}>
          {t('app.common.save')}
        </Button>
      </Flex>
    </Form>
  );
};

export interface EditGallerySpotifyUriFormPayload {
  values: EditGallerySpotifyUriFormValues;
  formikBag: FormikBag<EditGallerySpotifyUriFormProps, EditGallerySpotifyUriFormValues>;
}

export interface EditGallerySpotifyUriFormProps {
  onSubmit: (payload: EditGallerySpotifyUriFormPayload) => void;
  defaultValues: EditGallerySpotifyUriFormValues;
}

const gallerySpotifyUriSchema = yup.object({
  spotifyCode: yup.string(),
  // @ts-ignore
  spotifyUri: yup.string().spotifyUri().required(),
});

const editGallerySchema: yup.SchemaOf<EditGallerySpotifyUriFormValues> = gallerySpotifyUriSchema
  .shape({ id: yup.number().required() })
  .defined();

export const EditGallerySpotifyUriForm = withFormik<EditGallerySpotifyUriFormProps, EditGallerySpotifyUriFormValues>({
  handleSubmit: (values, formikBag) => {
    formikBag.props.onSubmit({ values, formikBag });
  },
  mapPropsToValues: ({ defaultValues }) => defaultValues,
  validationSchema: editGallerySchema,
})(GallerySpotifyUriForm);
