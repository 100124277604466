import { useCallback, useContext } from 'react';

import { useMutation, useQuery } from '@apollo/client';
import { App, Button, Empty } from 'antd';
import { useNavigate } from 'react-router-dom';

import ContentContainer from 'Components/Atoms/ContentContainer';
import Icon from 'Components/Atoms/Icon';
import Layout from 'Components/Atoms/Layout';
import Text from 'Components/Atoms/Text';

import Header from 'Components/Molecules/Header';
import ProductsTable, { OnChangeProps } from 'Components/Molecules/Tables/UserProductsTable';

import { NewProductFormPayload } from 'Forms/Product';

import { Metrics } from 'Themes';
import styled from 'Themes/Styled';

import { LocalizationContext } from 'i18n';

import { useModals } from 'Hooks/Modal';

import { PricingPlanType } from 'Operations/__generated__/graphql';

import { GET_PRODUCT_FILTERS } from 'Operations/Queries/Product/GetProductFilters';
import { GET_PRODUCTS } from 'Operations/Queries/Product/GetProducts';

import { CREATE_PRICING_PLAN } from 'Operations/Mutations/PricingPlan/CreatePricingPlan';
import { CREATE_PRODUCT } from 'Operations/Mutations/Product/CreateProduct';
import { DELETE_PRODUCT } from 'Operations/Mutations/Product/DeleteProduct';
import { DUPLICATE_PRODUCT } from 'Operations/Mutations/Product/DuplicateProduct';

const ProductsLayout = styled(ContentContainer)`
  flex: 1;
  align-self: flex-start;
  margin-top: ${Metrics.baseMargin}px;
`;

const CreateButton = styled(Button)`
  display: flex;
  align-items: center;
`;

const CreateButtonInContent = styled(CreateButton)`
  width: fit-content;
  margin: 0 auto;
  margin-top: ${Metrics.smallMargin}px;
`;

const PER_PAGE = 30;

const ProductsIndex = () => {
  const { t } = useContext(LocalizationContext);
  const { message } = App.useApp();
  const navigate = useNavigate();
  const { openModal, closeModal } = useModals();

  const {
    data,
    loading: isLoading,
    fetchMore,
  } = useQuery(GET_PRODUCTS, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-only',
    variables: { where: { page: 1, perPage: PER_PAGE } },
  });

  const { data: filtersData } = useQuery(GET_PRODUCT_FILTERS, {
    fetchPolicy: 'cache-and-network',
  });

  const [createProduct] = useMutation(CREATE_PRODUCT);

  const [deleteProduct] = useMutation(DELETE_PRODUCT);

  const [createPricingPlan] = useMutation(CREATE_PRICING_PLAN);

  const [duplicateProduct] = useMutation(DUPLICATE_PRODUCT);

  const handleCreateOnSubmit = useCallback(
    async ({
      values: {
        product: { enableCrop: _enableCrop, isDigital, withNewPricingPlan: _withNewPricingPlan, ...values },
        permitAdditionalItems: _permitAdditionalItems,
        withWholeGalleryPrice: _withWholeGalleryPrice,
        ...pricingPlanValues
      },
      formikBag,
    }: NewProductFormPayload) => {
      try {
        if (isDigital) {
          const pricingPlanResponse = await createPricingPlan({
            variables: {
              data: { ...pricingPlanValues, name: values.name },
            },
          });

          values.pricingPlanId = pricingPlanResponse.data?.createPricingPlan.id;
        }

        if (values.previewImage) {
          console.log(`size of logo to upload: ${values.previewImage?.size}`);
        }

        const product = await createProduct({ variables: { data: values } });

        message.success(t('app.message.product.create.success'));
        formikBag.setSubmitting(false);
        closeModal();

        if (product.data?.createProduct) {
          navigate(`/app/products/${product.data.createProduct.id}`);
        }
      } catch (error) {
        console.log(error);

        message.error(t('app.message.error.somethingWentWrong'));
        formikBag.setSubmitting(false);
      }
    },
    [closeModal, createProduct, createPricingPlan, navigate, t],
  );

  const handleShowProduct = useCallback(
    async ({ id }: { id: number }) => {
      try {
        navigate(`/app/products/${id}`);
      } catch (error) {
        console.log(error);

        message.error(t('app.message.error.somethingWentWrong'));
      }
    },
    [navigate, t],
  );

  const handleChange = useCallback(
    ({ page, categoryIds, contactIds }: OnChangeProps) => {
      if (fetchMore) {
        fetchMore({ variables: { where: { page, perPage: PER_PAGE, categoryIds, contactIds } } });
      }
    },
    [fetchMore],
  );

  const handleDuplicate = useCallback(
    async ({ id }: { id: number }) => {
      const result = await duplicateProduct({ variables: { where: { id } } });
      if (result.data?.duplicateProduct.id) {
        navigate(`/app/products/${result.data.duplicateProduct.id}`);
      }
    },
    [duplicateProduct, navigate],
  );

  const handleDeleteProduct = useCallback(
    async ({ id }: { id: number }) => {
      try {
        await deleteProduct({
          variables: {
            where: {
              id,
            },
          },
          update(cache) {
            const normalizedId = cache.identify({ id, __typename: 'Product' });
            cache.evict({ id: normalizedId });
            cache.gc();
          },
        });

        message.success(t('app.message.product.delete.success'));
      } catch (error) {
        console.log(error);

        message.error(t('app.message.error.somethingWentWrong'));
      }
    },
    [deleteProduct, t],
  );

  const handleOnCreateClick = useCallback(() => {
    openModal('PRODUCT', {
      name: 'new',
      onSubmit: handleCreateOnSubmit,
      defaultValues: {
        type: PricingPlanType.UNITARY,
        items: [
          {
            quantity: 1,
            price: 20,
            additionalPrice: null,
          },
        ],
        withWholeGalleryPrice: true,
        product: {
          name: '',
          previewImage: undefined,
          previewImageUrl: undefined,
          categoryId: undefined as unknown as number,
          contactId: undefined as unknown as number,
          presetProductId: undefined,
          description: '',
          maxPhotoCount: 1,
          photoSizes: [
            {
              width: undefined as unknown as number,
              height: undefined as unknown as number,
            },
          ],
          orderedByFotostudio: false,
          isPreconfiguredProduct: false,
          isFotostudioProduct: false,
          enableCrop: true,
          isDigital: false,
          pricingPlanId: undefined as unknown as number,
          weight: undefined,
        },
        permitAdditionalItems: false,
        wholeGalleryPrice: 0,
      },
      isAdmin: false,
    });
  }, [handleCreateOnSubmit, openModal]);

  return (
    <>
      <Header
        title={t('app.common.products', { count: 2 })}
        breadcrumbContent={[
          { text: t('app.menu.home'), url: '/app/dashboard' },
          { text: t('app.common.products', { count: 2 }) },
        ]}
        buttons={[
          <Button onClick={handleOnCreateClick} key="add" size="large" type="primary" icon={<Icon name="add" />}>
            {t('app.common.add')}
          </Button>,
        ]}
      />
      {!isLoading && data?.getProducts?.edges.length === 0 && (
        <>
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
          <Text align="center">{t('app.products.noData.text')}</Text>
          <CreateButtonInContent onClick={handleOnCreateClick} key="add" size="large" type="primary">
            <Icon name="add" size={18} />
            {t('app.products.noData.create')}
          </CreateButtonInContent>
        </>
      )}
      <Layout>
        <ProductsLayout>
          <ProductsTable
            products={data?.getProducts.edges || []}
            filters={filtersData?.getProductFilters}
            paginationSize={PER_PAGE}
            onChange={handleChange}
            isLoading={isLoading}
            onShow={handleShowProduct}
            onDelete={handleDeleteProduct}
            onDuplicate={handleDuplicate}
            total={data?.getProducts._count || 0}
          />
        </ProductsLayout>
      </Layout>
    </>
  );
};

export default ProductsIndex;
