import { gql } from 'Operations/__generated__';

export const UPDATE_GALLERY_PRODUCTS = gql(/* GraphQL */ `
  mutation UpdateGalleryProducts($where: IdInput!, $data: UpdateGalleryProductsInputType!) {
    updateGalleryProducts(where: $where, data: $data) {
      id
      catalog {
        id
      }
      includedProducts {
        ...IncludedProductCoreFields
      }
      mandatoryProduct {
        id
        name
      }
      allPhotosPrice
      allPhotosProductId
    }
  }
`);
