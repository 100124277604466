import React, { useContext, useEffect, useState } from 'react';

import {
  useLazyQuery,
  useMutation,
  useQuery,
  useReactiveVar
} from '@apollo/client';
import { App, Button, Input, Radio } from 'antd';
import { createStyles } from 'antd-style';

import { camelCase } from 'lodash';

import ContentContainer from 'Components/Atoms/ContentContainer';
import Icon from 'Components/Atoms/Icon';
import Layout from 'Components/Atoms/Layout';

import GalleriesGrid from 'Components/Molecules/GalleriesGrid';
import Header from 'Components/Molecules/Header';
import GalleryTable, {
  HandleChangeGalleryStatusProps,
  ShowGalleryProps
} from 'Components/Molecules/Tables/GalleryTable';

import { LocalizationContext } from 'i18n';

import { useGalleryApp } from 'Hooks/GalleryApp';
import { useModals } from 'Hooks/Modal';
import { useUpload } from 'Hooks/Upload';
import { useGalleryModal } from 'Hooks/useGalleryModal';
import { useJobSubscription } from 'Hooks/useJobSubscription';

import getErrorCode from 'Helpers/GetErrorCode';

import { UserConfigVar } from 'Operations/Cache';

import {
  DisplayType,
  GalleriesGetWhereType,
  GalleryOrderType,
  GalleryStatus,
  GalleryUpdateInputType
} from 'Operations/__generated__/graphql';

import { GET_GALLERIES } from 'Operations/Queries/Gallery/GetGalleries';
import { ME } from 'Operations/Queries/User/Me';

import { ARCHIVE_GALLERIES } from 'Operations/Mutations/Gallery/ArchiveGalleries';
import { DELETE_GALLERIES } from 'Operations/Mutations/Gallery/DeleteGalleries';
import { UPDATE_GALLERY } from 'Operations/Mutations/Gallery/UpdateGallery';
import upateUserConfig from 'Operations/Mutations/User/UpdateUserConfig';

const GALLERY_URL = process.env.REACT_APP_GALLERY_URL;

const useStyles = createStyles(({ css, token }) => ({
  container: css`
    flex: 1;
    align-self: flex-start;
    margin-top: ${token.size}px;
    gap: ${token.size}px;
  `,
  radioGroup: css`
    display: flex;
  `,
  displayIcon: css`
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  `,
}));

const PER_PAGE = 20;

const GalleriesIndex = () => {
  const { t } = useContext(LocalizationContext);
  const { onVisit } = useGalleryApp();
  const { closeModal } = useModals();
  const { modal, message } = App.useApp();
  const { styles } = useStyles();
  const { isUploadRunning, galleryId: galleryIdUpload } = useUpload();
  const userConfig = useReactiveVar(UserConfigVar);

  const [paginationParams, setPaginationParams] = useState<GalleriesGetWhereType>({
    page: 1,
    perPage: PER_PAGE,
    order: GalleryOrderType.DESC,
    status: [GalleryStatus.ONLINE, GalleryStatus.OFFLINE],
  });

  const page = paginationParams.page || 1;

  const { data: currentUser } = useQuery(ME, {
    fetchPolicy: 'cache-first',
  });

  const [getGalleries, { data, loading: isGalleriesLoading }] = useLazyQuery(GET_GALLERIES, {
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-only',
  });

  const refetchQueries = [
    {
      query: GET_GALLERIES,
      variables: {
        where: paginationParams,
      },
    },
  ];

  const { createGallery } = useGalleryModal({ refetchQueries });

  useJobSubscription();

  const [updateGallery] = useMutation(UPDATE_GALLERY, {
    refetchQueries,
  });

  const [archiveGalleries] = useMutation(ARCHIVE_GALLERIES, {
    awaitRefetchQueries: true,
    refetchQueries,
    onCompleted: () => {
      if (data && data?.getPaginatedGalleries?._count % PER_PAGE === 0 && page > 1) {
        setPaginationParams({ ...paginationParams, page: page - 1 });
      }
    },
  });
  const [deleteGalleries] = useMutation(DELETE_GALLERIES, {
    refetchQueries,
    awaitRefetchQueries: true,
    onCompleted: () => {
      if (data && data?.getPaginatedGalleries?._count % PER_PAGE === 0 && page > 1) {
        setPaginationParams({ ...paginationParams, page: page - 1 });
      }
    },
  });

  useEffect(() => {
    getGalleries({
      variables: {
        where: paginationParams,
      },
    });
  }, [getGalleries, paginationParams]);

  const handleFilterTable = async (params: GalleriesGetWhereType) => {
    setPaginationParams(oldParams => ({
      ...oldParams,
      ...params,
      status: [GalleryStatus.ONLINE, GalleryStatus.OFFLINE],
    }));
  };

  const handleShowGalleryClick = ({ slug, url }: ShowGalleryProps) => {
    if (GALLERY_URL) {
      onVisit();

      const companyUrl = currentUser?.me?.company.bookingUrl;
      let galleryUrl = `${GALLERY_URL}/${slug}`;

      if (companyUrl && url) {
        galleryUrl = `${GALLERY_URL}/${companyUrl}/${url}`;
      }

      window.open(galleryUrl, '_blank');
    }
  };

  const handleDeleteClick = async ({ ids }: { ids: number[] }) => {
    return onDelete({ ids, forceDelete: false });
  };

  const handleArchiveClick = async ({ ids }: { ids: number[] }) => {
    return onArchive({ ids, forceArchiving: false });
  };

  const onArchive = async ({ ids, forceArchiving }: { ids: number[]; forceArchiving?: boolean }) => {
    try {
      await archiveGalleries({
        variables: {
          where: { ids },
          data: { forceArchiving },
        },
      });

      message.success(t('app.message.gallery.archive.success'));
    } catch (error) {
      const errorCode = getErrorCode(error);
      if (errorCode === 'ARCHIVE_NOT_ALLOWED_WITH_OPEN_ORDERS') {
        modal.confirm({
          title: t('app.message.gallery.warning.orderOpen'),
          okText: t('app.common.archive'),
          cancelText: t('app.common.cancel'),
          onOk() {
            onArchive({ ids, forceArchiving: true });
          },
        });
        return;
      }
      message.error(t(`app.message.error.${camelCase(errorCode)}`));
    }
  };

  const onDelete = async ({ ids, forceDelete }: { ids: number[]; forceDelete: boolean }) => {
    try {
      await deleteGalleries({
        variables: {
          where: { ids },
          data: { forceDelete },
        },
      });

      message.success(t('app.message.gallery.delete.success'));
    } catch (error) {
      const errorCode = getErrorCode(error);
      if (errorCode === 'DELETE_NOT_ALLOWED_ORDERS') {
        modal.confirm({
          title: t('app.message.gallery.warning.orderOpen'),
          okText: t('app.gallery.moveToTrash'),
          cancelText: t('app.common.cancel'),
          onOk() {
            onDelete({ ids, forceDelete: true });
          },
        });
        return;
      }

      message.error(t(`app.message.gallery.delete.error.${camelCase(errorCode)}`));
    }
  };

  const handleChangeGalleryStatus = async ({ id, isChecked }: HandleChangeGalleryStatusProps) => {
    try {
      const data: GalleryUpdateInputType = {
        status: isChecked ? GalleryStatus.ONLINE : GalleryStatus.OFFLINE,
        availableAt: isChecked ? null : undefined,
        expiredAt: isChecked ? undefined : null,
      };

      await updateGallery({
        variables: {
          where: { id },
          data,
        },
      });

      message.success(t('app.message.gallery.update.success'));

      closeModal();
    } catch (error) {
      console.log(error);

      const errorCode = getErrorCode(error);
      switch (errorCode) {
        default:
          message.error(t('app.message.error.somethingWentWrong'));
          break;
      }
    }
  };

  return (
    <>
      <Header
        title={t('app.gallery.recent.title')}
        breadcrumbContent={[{ text: t('app.menu.home'), url: '/app/dashboard' }, { text: t('app.menu.lastGalleries') }]}
        buttons={[
          <Input.Search
            placeholder={t('app.common.search')}
            size="large"
            enterButton
            onSearch={search => handleFilterTable({ search })}
            allowClear
          />,
          <Radio.Group
            value={userConfig?.galleriesDisplay || DisplayType.GRID}
            optionType="button"
            buttonStyle="solid"
            className={styles.radioGroup}
            onChange={e => upateUserConfig({ galleriesDisplay: e.target.value })}
            size="large"
            options={[
              {
                label: (
                  <div className={styles.displayIcon}>
                    <Icon name="table" />
                  </div>
                ),
                value: DisplayType.TABLE,
              },
              {
                label: (
                  <div className={styles.displayIcon}>
                    <Icon name="gridItems" />
                  </div>
                ),
                value: DisplayType.GRID,
              },
            ]}
          />,
          <Button icon={<Icon name="add" />} key="add" onClick={() => createGallery()} size="large" type="primary">
            {t('app.common.add')}
          </Button>,
        ]}
      />
      <Layout>
        <ContentContainer className={styles.container}>
          {userConfig?.galleriesDisplay === DisplayType.TABLE && (
            <GalleryTable
              galleriesTotal={data ? data.getPaginatedGalleries._count : 0}
              data={data?.getPaginatedGalleries.edges}
              loading={isGalleriesLoading}
              paginationCurrent={page}
              paginationSize={paginationParams.perPage || PER_PAGE}
              galleryIdUpload={galleryIdUpload}
              isUploadRunning={isUploadRunning}
              handleOnChange={handleFilterTable}
              handleShowGalleryClick={handleShowGalleryClick}
              handleDeleteConfirm={handleDeleteClick}
              handleArchiveConfirm={handleArchiveClick}
              handleChangeGalleryStatus={handleChangeGalleryStatus}
            />
          )}
          {userConfig?.galleriesDisplay === DisplayType.GRID && (
            <GalleriesGrid
              galleriesTotal={data ? data.getPaginatedGalleries._count : 0}
              data={data?.getPaginatedGalleries.edges}
              loading={isGalleriesLoading}
              paginationCurrent={page}
              paginationSize={paginationParams.perPage || PER_PAGE}
              galleryIdUpload={galleryIdUpload}
              isUploadRunning={isUploadRunning}
              handleOnChange={handleFilterTable}
              handleShowGalleryClick={handleShowGalleryClick}
              handleDeleteConfirm={handleDeleteClick}
              handleArchiveConfirm={handleArchiveClick}
              handleChangeGalleryStatus={handleChangeGalleryStatus}
            />
          )}
        </ContentContainer>
      </Layout>
    </>
  );
};

export default GalleriesIndex;
