import React, { useContext } from 'react';

import { Button, Flex, Table } from 'antd';

import { Formik } from 'formik';
import { Form, InputNumber, Switch } from 'formik-antd';

import Text from 'Components/Atoms/Text';
import Title from 'Components/Atoms/Title';

import RoundButton from 'Components/Molecules/Buttons/RoundButton';
import Select from 'Components/Molecules/Form/Select';

import { LocalizationContext } from 'i18n';

import { UpdateGalleryProductsInputType } from 'Operations/__generated__/graphql';

export interface AllPhotosPriceValue
  extends Pick<UpdateGalleryProductsInputType, 'allPhotosPrice' | 'allPhotosProductId'> {}

interface Props {
  title?: string;
  titleActions?: React.ReactNode;
  allPhotosPrices: AllPhotosPriceValue[];
  isLoading: boolean;
  loadMoreProducts: () => void;
  maxSelectableProducts?: number;
  hasFetchAllProducts: boolean;
  products: Array<{ id: number; name: string }>;
  onChange?: (values: { allPhotosPrices: AllPhotosPriceValue[]; errors?: Record<string, string> }) => void;
  emptyText?: React.ReactNode;
  style?: React.CSSProperties;
  className?: string;
}

const AllPhotosPriceForm = ({
  title,
  titleActions,
  allPhotosPrices,
  maxSelectableProducts,
  hasFetchAllProducts,
  products,
  loadMoreProducts,
  isLoading,
  onChange,
  emptyText,
  ...props
}: Props) => {
  const { t } = useContext(LocalizationContext);

  return (
    <Formik
      initialValues={{
        allPhotosPrices,
      }}
      enableReinitialize
      validate={values => {
        // We can't have the same product twice
        const productIds = values.allPhotosPrices.map(ip => ip.allPhotosProductId);
        const duplicateProductIndex = productIds.findIndex((id, i) => productIds.indexOf(id) !== i);

        let errors: Record<string, string> | undefined;

        if (duplicateProductIndex >= 0) {
          errors = { includedProducts: t('app.form.errors.duplicateIncludedProducts') };
        }

        onChange?.({ ...values, errors });

        return errors;
      }}
      // Don't submit the form as we are handling submit in the parent component with the onChange prop
      onSubmit={values => {}}
    >
      {({ values, setFieldValue, errors }) => (
        <Form>
          <Table
            title={
              title
                ? () => (
                    <Flex justify="space-between" align="center" gap={2}>
                      <Title level="h5">{title}</Title>
                      {titleActions && (
                        <Flex justify="end" align="center" gap={2}>
                          {titleActions}
                        </Flex>
                      )}
                    </Flex>
                  )
                : undefined
            }
            dataSource={values.allPhotosPrices}
            loading={isLoading}
            pagination={false}
            locale={{
              emptyText,
            }}
            size="small"
            columns={[
              {
                title: t('app.common.products', { count: 1 }),
                dataIndex: 'productId',
                key: 'productId',
                width: '40%',
                render(value, record, index) {
                  return (
                    <Select
                      style={{ width: '100%' }}
                      name={`allPhotosPrices.${index}.allPhotosProductId`}
                      size="large"
                      loadMore={() => loadMoreProducts}
                      hasFetchAll={hasFetchAllProducts}
                      loading={isLoading}
                      getPopupContainer={trigger => trigger.parentNode}
                    >
                      {products.map(product => (
                        <Select.Option key={product.id} value={product.id} title={''}>
                          {product.name}
                        </Select.Option>
                      ))}
                    </Select>
                  );
                },
              },
              {
                title: t('app.common.price'),
                dataIndex: 'price',
                key: 'price',
                render(value, record, index) {
                  return <InputNumber name={`allPhotosPrices.${index}.allPhotosPrice`} size="large" />;
                },
              },
              {
                title: t('app.common.actions'),
                key: 'actions',
                width: 50,
                render(value, record, index) {
                  return (
                    <RoundButton
                      icon="delete"
                      danger
                      onClick={() => {
                        setFieldValue(
                          'allPhotosPrices',
                          values.allPhotosPrices.filter((_, i) => i !== index),
                        );
                      }}
                    />
                  );
                },
              },
            ]}
            footer={() => {
              const notAlreadyAddedProduct = products.find(
                p => !values.allPhotosPrices.find(ip => ip.allPhotosProductId === p.id),
              );
              const isDuplicateProduct = values.allPhotosPrices.some(
                (ip, i) =>
                  values.allPhotosPrices.findIndex(ip2 => ip2.allPhotosProductId === ip.allPhotosProductId) !== i,
              );

              if (
                !isDuplicateProduct && // We can't have the same product twice
                notAlreadyAddedProduct && // There are still products that are not already added
                (maxSelectableProducts === undefined || values.allPhotosPrices.length < maxSelectableProducts) // We can still add more products
              ) {
                return (
                  <Button
                    type="dashed"
                    block
                    onClick={() => {
                      setFieldValue('allPhotosPrices', [
                        ...values.allPhotosPrices,
                        {
                          allPhotosProductId: notAlreadyAddedProduct.id,
                          allPhotosPrice: 200,
                        },
                      ]);
                    }}
                  >
                    {t('app.products.add', { count: 1 })}
                  </Button>
                );
              }

              return null;
            }}
            {...props}
          />
          {errors.allPhotosPrices && (
            <Text size="medium" color="red" align="center" style={{ width: '100%' }}>
              {errors.allPhotosPrices as string}
            </Text>
          )}
        </Form>
      )}
    </Formik>
  );
};

export default AllPhotosPriceForm;
