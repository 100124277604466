import { gql } from 'Operations/__generated__';

export const UPDATE_GALLERY_PRESET_PRODUCTS = gql(/* GraphQL */ `
  mutation UpdateGalleryPresetProducts($where: IdInput!, $data: UpdateGalleryProductsInputType!) {
    updateGalleryPresetProducts(where: $where, data: $data) {
      id
      catalog {
        id
      }
      includedProducts {
        ...IncludedProductCoreFields
      }
      mandatoryProduct {
        id
        name
      }
    }
  }
`);
