import React, { useCallback, useContext, useMemo, useState } from 'react';

import { ApolloCache, useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { App, Button, Flex } from 'antd';
import { ReplaceWithReference } from 'types/ModelConnection';

import { flatMap } from 'lodash';

import Icon from 'Components/Atoms/Icon';

import PhotosDrawer from 'Components/Molecules/Drawers/PhotosDrawer';
import SelectionToolbar from 'Components/Molecules/SelectionToolbar';

import { LocalizationContext } from 'i18n';

import useGallery from 'Hooks/useGallery';

import { PhotoAdmin, PhotoCharacteristic, Picklist } from 'Operations/__generated__/graphql';

import { GET_PHOTOS } from 'Operations/Queries/Photo/GetPhotos';
import { GET_UNPICKED_PHOTOS } from 'Operations/Queries/Photo/GetUnpickedPhotos';
import { GET_PICKLIST } from 'Operations/Queries/Picklist/GetPicklist';

import { ADD_PHOTOS_TO_PICKLIST } from 'Operations/Mutations/Picklist/addPhotosToPicklist';
import { REMOVE_PHOTOS_FROM_PICKLIST } from 'Operations/Mutations/Picklist/removePhotosFromPicklist';

interface Props {
  picklistId?: number;
  galleryId: number;
  onClose: () => void;
}

const perPage = 50;

const PicklistDrawer = ({ picklistId, galleryId, onClose }: Props) => {
  const { t } = useContext(LocalizationContext);
  const { gallery } = useGallery({ id: galleryId });
  const { message } = App.useApp();

  const [page, setPage] = useState(1);
  const [selectedPhotoIds, setSelectedPhotoIds] = useState<number[]>([]);
  const [selectedPhotosIdsToAdd, setSelectedPhotosIdsToAdd] = useState<number[]>([]);
  const [isPhotosDrawerOpen, setIsPhotosDrawerOpen] = useState(false);

  const [removePhotosFromPicklist] = useMutation(REMOVE_PHOTOS_FROM_PICKLIST);
  const [addPhotosToPicklist] = useMutation(ADD_PHOTOS_TO_PICKLIST);

  const { data } = useQuery(GET_PICKLIST, {
    fetchPolicy: 'cache-and-network',
    skip: !picklistId || picklistId < 0,
    variables: {
      where: {
        id: picklistId as number,
      },
      wherePhotos: {
        page,
        perPage,
      },
    },
  });
  const { data: likedPhotos } = useQuery(GET_PHOTOS, {
    fetchPolicy: 'cache-and-network',
    skip: !picklistId || picklistId > 0,
    variables: {
      where: {
        galleryId,
        characteristics: [PhotoCharacteristic.AVAILABLE, PhotoCharacteristic.LIKED],
        page,
        perPage,
      },
    },
  });

  const [getUnpickedPhotos, { data: photosData, fetchMore }] = useLazyQuery(GET_UNPICKED_PHOTOS, {
    fetchPolicy: 'cache-and-network',
    variables: {
      where: {
        galleryId,
        picklistId: picklistId as number,
      },
      paginate: {
        page: 1,
        perPage,
      },
    },
  });

  const photos = useMemo(
    () => ((picklistId || 1) > 0 ? data?.getPicklist.photos : likedPhotos?.getPhotos),
    [data?.getPicklist.photos, likedPhotos?.getPhotos],
  );
  const photoToAdd = useMemo(
    () => photosData?.getUnpickedPhotos,
    [photosData?.getUnpickedPhotos._count, photosData?.getUnpickedPhotos.edges],
  );

  const updateCache = useCallback(
    ({ cache, photoIds, add = false }: { cache: ApolloCache<any>; photoIds: number[]; add?: boolean }) => {
      cache.modify<ReplaceWithReference<Picklist, 'photos'>>({
        id: `Picklist:${picklistId}`,
        fields: {
          photos(existing) {
            if ('_count' in existing) {
              const edges = add
                ? [...(existing?.edges || []), ...photoIds.map(photoId => ({ __ref: `PhotoAdmin:${photoId}` }))]
                : existing?.edges?.filter(({ __ref }) => !photoIds.includes(parseInt(__ref.split(':')[1], 10)));

              const photoIdsOrder = flatMap(gallery?.photosCustomOrder || {});

              // order edges by custom order
              edges.sort((a, b) => {
                const aIndex = photoIdsOrder.indexOf(parseInt(a.__ref.split(':')[1], 10));
                const bIndex = photoIdsOrder.indexOf(parseInt(b.__ref.split(':')[1], 10));
                return aIndex - bIndex;
              });

              return {
                ...(existing || {}),
                edges,
                _count: (existing?._count || 0) + (add ? photoIds.length : -photoIds.length),
              };
            }
            return existing;
          },
        },
      });

      setSelectedPhotoIds([]);
      setSelectedPhotosIdsToAdd([]);
    },
    [picklistId],
  );

  const handleAddPhotosToPicklist = useCallback(() => {
    setIsPhotosDrawerOpen(false);
    if (picklistId && selectedPhotosIdsToAdd.length > 0) {
      addPhotosToPicklist({
        variables: {
          where: { id: picklistId },
          data: { ids: selectedPhotosIdsToAdd },
        },
        update: cache => updateCache({ cache, photoIds: selectedPhotosIdsToAdd, add: true }),
      });
    }
  }, [addPhotosToPicklist, picklistId, selectedPhotosIdsToAdd, setIsPhotosDrawerOpen, updateCache]);

  const handleRemovePhotosFromPicklist = useCallback(() => {
    if (picklistId) {
      removePhotosFromPicklist({
        variables: {
          where: { id: picklistId },
          data: { ids: selectedPhotoIds },
        },
        update: cache => updateCache({ cache, photoIds: selectedPhotoIds }),
      });
    }
  }, [removePhotosFromPicklist, selectedPhotoIds, picklistId, updateCache]);

  const handleOnSelectPhoto = useCallback(
    ({ photoId, prevState }: { prevState: number[]; photoId: number }) =>
      prevState.includes(photoId) ? prevState.filter(id => id !== photoId) : [...prevState, photoId],
    [],
  );

  const handleOpenPhotosDrawer = useCallback(async () => {
    await getUnpickedPhotos();
    setIsPhotosDrawerOpen(true);
  }, [getUnpickedPhotos]);

  const onCancelSelection = useCallback(() => {
    setSelectedPhotoIds([]);
  }, []);

  const selectAllPhotos = useCallback(() => {
    setSelectedPhotoIds((photos?.edges as PhotoAdmin[]).map(p => p.id) || []);
  }, [photos]);

  const onLightroomSelection = useCallback(() => {
    const photosName = selectedPhotoIds
      .map(photoId => {
        const photo = photos?.edges.find(p => p.__typename === 'PhotoAdmin' && p.id === photoId) as PhotoAdmin;

        // Remove extension
        return photo?.name.replace(/\.[^/.]+$/, '');
      })
      .filter(name => !!name);

    navigator.clipboard.writeText(photosName.join(', '));

    message.success(t('app.message.gallery.photos.clipboard.success', { count: selectedPhotoIds.length }));
  }, [message, photos, selectedPhotoIds, t]);

  return (
    <PhotosDrawer
      title={data?.getPicklist.name}
      open={!!picklistId}
      onClose={onClose}
      width={675}
      extra={[
        <SelectionToolbar
          selectionCount={selectedPhotoIds.length || 0}
          onCancel={onCancelSelection}
          onLightroom={onLightroomSelection}
          onDelete={handleRemovePhotosFromPicklist}
          onSelectAll={photos?.edges?.length ? selectAllPhotos : undefined}
        />,
      ]}
      footer={
        picklistId &&
        picklistId > 0 && (
          <Flex justify="space-between">
            <Button
              icon={<Icon name="minus" size={12} />}
              danger
              disabled={selectedPhotoIds.length === 0}
              onClick={handleRemovePhotosFromPicklist}
            >
              {t('app.common.delete')}
            </Button>
            <Button icon={<Icon name="plus" size={12} />} type="primary" onClick={handleOpenPhotosDrawer}>
              {t('app.common.addPhotos')}
            </Button>
          </Flex>
        )
      }
      photos={photos}
      selectedPhotoIds={selectedPhotoIds}
      onSelect={
        picklistId && picklistId > 0
          ? photoId => setSelectedPhotoIds(prevState => handleOnSelectPhoto({ prevState, photoId }))
          : undefined
      }
      fetchMore={() => setPage(page + 1)}
    >
      {photoToAdd && (
        <PhotosDrawer
          title={t('app.common.addPhotos')}
          extra={<Button onClick={handleAddPhotosToPicklist}>{t('app.common.add')}</Button>}
          onClose={() => setIsPhotosDrawerOpen(false)}
          width={525}
          open={isPhotosDrawerOpen}
          photos={photoToAdd}
          selectedPhotoIds={selectedPhotosIdsToAdd}
          onSelect={photoId => setSelectedPhotosIdsToAdd(prevState => handleOnSelectPhoto({ prevState, photoId }))}
          fetchMore={() =>
            fetchMore({
              variables: {
                paginate: { page: photoToAdd.pageInfo.currentPage + 1, perPage },
              },
            })
          }
        />
      )}
    </PhotosDrawer>
  );
};

export default PicklistDrawer;
