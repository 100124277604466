import { useCallback, useContext, useEffect, useMemo } from 'react';

import { useMutation, useQuery } from '@apollo/client';
import {
  Alert,
  App,
  Button,
  Dropdown,
  Flex,
  Menu,
  Spin,
  Switch,
  Tabs
} from 'antd';
import { createStyles } from 'antd-style';
import { useNavigate, useParams } from 'react-router-dom';

import { camelCase } from 'lodash';

import ContentContainer from 'Components/Atoms/ContentContainer';
import Icon from 'Components/Atoms/Icon';
import Layout from 'Components/Atoms/Layout';

import RoundButton from 'Components/Molecules/Buttons/RoundButton';
import { WizardPayload } from 'Components/Molecules/Form/Wizard';
import GalleryJobTitle from 'Components/Molecules/GalleryJobTitle';
import Header from 'Components/Molecules/Header';
import StatCard from 'Components/Molecules/Stats/StatCard';
import { HandleChangeGalleryStatusProps } from 'Components/Molecules/Tables/GalleryTable';

import GalleryAppearanceTab from 'Pages/App/Galleries/Tabs/GalleryAppearanceTab';
import GalleryOrdersTab from 'Pages/App/Galleries/Tabs/GalleryOrdersTab';
import GalleryPhotosTab from 'Pages/App/Galleries/Tabs/GalleryPhotosTab';
import GalleryRetouchTab, { OnSendEmailProps } from 'Pages/App/Galleries/Tabs/GalleryRetouchTab';
import GalleryWatermarksTab from 'Pages/App/Galleries/Tabs/GalleryWatermarksTab';
import GalleriesShowSkeleton from 'Pages/App/Skeletons/GalleriesShowSkeleton';

import { GalleryFormValues } from 'Forms/Gallery/types';
import { SendEmailPayload } from 'Forms/SendEmail';

import { Colors } from 'Themes';

import { LocalizationContext } from 'i18n';

import { useGalleryApp } from 'Hooks/GalleryApp';
import { useModals } from 'Hooks/Modal';
import { useUpload } from 'Hooks/Upload';
import { useContactModal } from 'Hooks/useContactModal';
import useGallery from 'Hooks/useGallery';
import { useJobSubscription } from 'Hooks/useJobSubscription';
import { usePhotosCount } from 'Hooks/usePhotosCount';

import getErrorCode from 'Helpers/GetErrorCode';

import {
  EmailModelType,
  EmailTemplateAssociatedModel,
  GalleryAccessPolicy,
  GalleryStatus,
  GalleryUpdateInputType,
  GalleryWorkmode,
  OrderCharacteristic,
  PhotoCharacteristic,
  SendEmailInput
} from 'Operations/__generated__/graphql';

import { GET_GALLERIES_ACCESS } from 'Operations/Queries/Gallery/GetGalleriesAccess';
import { GET_GALLERY } from 'Operations/Queries/Gallery/GetGallery';
import { GET_ORDERS_COUNT } from 'Operations/Queries/Order/GetOrdersCount';

import { SEND_EMAIL } from 'Operations/Mutations/Email/SendEmail';
import { ARCHIVE_GALLERIES } from 'Operations/Mutations/Gallery/ArchiveGalleries';
import { DELETE_GALLERY } from 'Operations/Mutations/Gallery/DeleteGallery';
import { SEE_GALLERY } from 'Operations/Mutations/Gallery/SeeGallery';
import { UPDATE_GALLERY } from 'Operations/Mutations/Gallery/UpdateGallery';
import { DUPLICATE_GALLERY_PRESET } from 'Operations/Mutations/GalleryPreset/DuplicateGalleryPreset';

import GalleryAccessTab from './Tabs/GalleryAccessTab';
import GalleryCommentsTab from './Tabs/GalleryCommentsTab';
import GalleryExtraTab from './Tabs/GalleryExtraTab';
import GalleryPicklistsTab from './Tabs/GalleryPicklistsTab';
import GalleryProductsTab from './Tabs/GalleryProductsTab';

const GALLERY_URL = process.env.REACT_APP_GALLERY_URL;
const CRM_URL = process.env.REACT_APP_CRM_URL;

const useStyles = createStyles(({ css, token }) => ({
  tabsContainer: css`
    flex: 1;
    width: 100%;
    padding-top: 0 !important;
  `,
  tabs: css`
    flex: 1;
  `,
  tabPane: css`
    padding-top: ${token.sizeSM}px;
    padding-bottom: ${token.sizeSM}px;
  `,
  alert: css`
    width: 100%;
    margin-bottom: ${token.size}px;
    padding: ${token.sizeSM}px;
  `,
  jobTooltip: css`
    display: flex;
    gap: ${token.sizeSM}px;
  `,
}));

const GalleriesShow = () => {
  const { t } = useContext(LocalizationContext);
  const { openModal, closeModal } = useModals();
  const { modal, message } = App.useApp();
  const navigate = useNavigate();
  const { onVisit } = useGalleryApp();
  const { styles } = useStyles();
  const { isUploadRunning, galleryId: galleryIdUpload } = useUpload();

  const { id } = useParams<{ id: string }>();
  const galleryId = id ? parseInt(id, 10) : undefined;
  const { galleryRootUrl, gallery, isCalled, isGalleryLoading, error } = useGallery({ id: galleryId });

  const refetchQueries = [
    {
      query: GET_GALLERY,
      variables: { where: { id: galleryId } },
    },
    {
      query: GET_GALLERIES_ACCESS,
      variables: { where: { galleryId } },
    },
  ];

  const { updateContact } = useContactModal({ refetchQueries });

  const { count: galleryPhotoCount, isLoading: isGalleryPhotoCountLoading } = usePhotosCount({
    galleryId,
    characteristics: [PhotoCharacteristic.AVAILABLE],
  });

  const { count: retouchedPhotoCount, isLoading: isRetouchedLoading } = usePhotosCount({
    galleryId,
    characteristics: [PhotoCharacteristic.RETOUCHED],
    skip: !gallery || gallery.workmode !== GalleryWorkmode.RETOUCH_FIRST,
  });

  const { count: needRetouchPhotoCount, isLoading: isNeedRetouchLoading } = usePhotosCount({
    skip: !gallery || gallery.workmode !== GalleryWorkmode.RETOUCH_FIRST,
    galleryId,
    characteristics: [PhotoCharacteristic.NEEDS_RETOUCH, PhotoCharacteristic.AVAILABLE],
  });

  const { data: validatedOrders } = useQuery(GET_ORDERS_COUNT, {
    skip: !gallery || gallery.workmode !== GalleryWorkmode.RETOUCH_FIRST,
    variables: {
      where: {
        galleryId,
        isValidated: true,
        characteristics: [OrderCharacteristic.OPEN],
      },
    },
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
  });

  const { data: orders } = useQuery(GET_ORDERS_COUNT, {
    skip: !gallery,
    variables: {
      where: {
        galleryId,
        characteristics: [OrderCharacteristic.OPEN],
      },
    },
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
  });

  useJobSubscription();

  const [updateGallery, { loading: isUpdateLoading }] = useMutation(UPDATE_GALLERY, {
    refetchQueries,
  });

  const [archiveGalleries, { loading: isArchiveLoading }] = useMutation(ARCHIVE_GALLERIES, {
    refetchQueries,
  });

  const [deleteGallery] = useMutation(DELETE_GALLERY);

  const [sendEmail] = useMutation(SEND_EMAIL);

  const [seeGallery] = useMutation(SEE_GALLERY);

  const [duplicateGalleryPreset] = useMutation(DUPLICATE_GALLERY_PRESET);

  useEffect(() => {
    if (galleryId) {
      seeGallery({
        variables: {
          where: {
            id: galleryId,
          },
        },
      });
    }
  }, [galleryId, seeGallery]);

  const handleEditOnSubmit = useCallback(
    async ({
      values: { id, catalogName, galleryPresetName, ...galleryData },
      formikBag,
      setStep,
    }: WizardPayload<GalleryFormValues>) => {
      if (!id) {
        throw new Error('Missing gallery id');
      }
      try {
        await updateGallery({
          variables: {
            where: { id },
            data: {
              ...galleryData,
            },
          },
        });

        message.success(t('app.message.gallery.update.success'));
        formikBag.setSubmitting(false);

        closeModal();
      } catch (error) {
        const errorCode = getErrorCode(error);
        let fieldName = null;
        let errorMessage = t(`app.message.error.somethingWentWrong`);

        switch (errorCode) {
          case 'GALLERY_ACCESS_CODE_WITHOUT_CONTACT':
            errorMessage = t(`app.message.error.${camelCase(errorCode)}`);
            setStep('informations');
            break;
          case 'UNIQ_CONSTRAINT_FAILED':
            fieldName = 'url';
            errorMessage = t(`app.message.error.${camelCase(errorCode)}`, { field: 'url' });
            break;
          case 'GALLERY_HAS_ORDERS':
            if (error.message.indexOf('workmode') >= 0) {
              fieldName = 'workmode';
              setStep('workmode');
            } else if (error.message.indexOf('included photos') >= 0) {
              fieldName = 'includedPhotosCount';
            }
            if (fieldName) {
              errorMessage = t(`app.message.error.${camelCase(errorCode)}.${fieldName}`);
            }
            break;
        }

        if (fieldName) {
          formikBag.setFieldError(fieldName, errorMessage);
        }
        message.error(errorMessage);

        formikBag.setSubmitting(false);
      }
    },
    [closeModal, t, updateGallery],
  );

  const handleSendEmail = useCallback(
    async ({
      values: { attachmentName: _attName, attachmentUrl: _attUrl, templateAssociatedModel, ...values },
      formikBag,
    }: SendEmailPayload) => {
      try {
        if (galleryId && values.contactId) {
          const sendEmailInput: SendEmailInput = {
            contactId: values.contactId,
            content: values.content,
            modelId: galleryId,
            modelType:
              templateAssociatedModel === EmailTemplateAssociatedModel.GALLERYORDERAVAILABLE
                ? EmailModelType.GALLERYORDERAVAILABLE
                : EmailModelType.GALLERY,
            title: values.title,
            attachment: values.attachment,
            secondaryContactIds: values.secondaryContactIds,
            templateId: values.templateId,
          };
          await sendEmail({
            variables: { data: sendEmailInput },
          });

          formikBag.setSubmitting(false);
          closeModal();

          message.success(t('app.message.gallery.sendEmail.success'));
        }
      } catch (error) {
        console.log(error);

        message.error(t('app.message.error.somethingWentWrong'));
        formikBag.setSubmitting(false);
      }
    },
    [closeModal, id, sendEmail, t],
  );

  const handleChangeGalleryStatus = async ({ id, isChecked }: HandleChangeGalleryStatusProps) => {
    try {
      const data: GalleryUpdateInputType = {
        status: isChecked ? GalleryStatus.ONLINE : GalleryStatus.OFFLINE,
        availableAt: isChecked ? null : undefined,
        expiredAt: isChecked ? undefined : null,
      };

      await updateGallery({
        variables: {
          where: { id },
          data,
        },
      });

      closeModal();
    } catch (error) {
      const errorCode = getErrorCode(error);
      switch (errorCode) {
        default:
          message.error(t('app.message.error.somethingWentWrong'));
          break;
      }
    }
  };

  const handleDuplicateGalleryPreset = useCallback(
    async ({ id }: { id: number }) => {
      const preset = await duplicateGalleryPreset({
        variables: {
          where: {
            galleryId: id,
          },
        },
      });

      message.success(t('app.message.galleryPreset.duplicate.success'));

      if (preset.data?.duplicateGalleryPreset.id) {
        navigate(`/app/galleries/presets/${preset.data.duplicateGalleryPreset.id}`);
      }
    },
    [duplicateGalleryPreset, t],
  );

  const galleryHiddenPhotosCount = useMemo(
    () => gallery?.photosCustomOrder.default.length || 0,
    [gallery?.photosCustomOrder],
  );
  const galleryPhotosCount = useMemo(
    () =>
      !!gallery?.photosCustomOrder
        ? Object.keys(gallery.photosCustomOrder)
            .filter(key => key !== 'default')
            .reduce((acc, key) => acc + gallery.photosCustomOrder[key].length, 0)
        : 0,
    [gallery?.photosCustomOrder],
  );

  const isOnlyDefaultFolder = useMemo(
    () => galleryHiddenPhotosCount > 0 && galleryPhotosCount === 0,
    [galleryHiddenPhotosCount, galleryPhotosCount],
  );

  if ((isGalleryLoading && !isCalled) || isRetouchedLoading || isNeedRetouchLoading) {
    return <GalleriesShowSkeleton />;
  }

  if (error || !gallery) {
    return null;
  }

  if (gallery.status === GalleryStatus.ARCHIVED) {
    navigate('/app/galleries/archived');
    return null;
  }

  const {
    name,
    contact,
    _count,
    galleryAppearance,
    watermark,
    watermarkMode,
    photosOrder,
    photosCustomOrder,
    workmode,
    catalog,
    includedPhotosCount,
    jobs,
    project,
  } = gallery;

  const isNoEmailOnContact = !!gallery.contact && !gallery.contact.email;
  const isActionsDisabled = isUploadRunning && galleryIdUpload === galleryId;

  const handleArchiveGallery = async ({ forceArchiving }: { forceArchiving?: boolean }) => {
    try {
      if (galleryId) {
        await archiveGalleries({
          variables: {
            where: { ids: [galleryId] },
            data: { forceArchiving },
          },
        });

        message.success(t('app.message.gallery.archive.success'));
        navigate('/app/galleries/archived');
      }
    } catch (error) {
      const errorCode = getErrorCode(error);
      if (errorCode === 'ARCHIVE_NOT_ALLOWED_WITH_OPEN_ORDERS') {
        modal.confirm({
          title: t('app.message.gallery.warning.orderOpen'),
          okText: t('app.common.archive'),
          cancelText: t('app.common.cancel'),
          onOk() {
            handleArchiveGallery({ forceArchiving: true });
          },
        });
        return;
      }
      message.error(t(`app.message.error.${camelCase(errorCode)}`));
    }
  };

  const handleDeleteGallery = async ({ forceDelete }: { forceDelete?: boolean }) => {
    try {
      if (galleryId) {
        await deleteGallery({
          variables: {
            where: {
              id: galleryId,
            },
            data: {
              forceDelete,
            },
          },
        });

        message.success(t('app.message.gallery.delete.success'));
        navigate('/app/galleries/trash');
      }
    } catch (error) {
      const errorCode = getErrorCode(error);
      if (errorCode === 'DELETE_NOT_ALLOWED_ORDERS') {
        modal.confirm({
          title: t('app.message.gallery.warning.orderOpen'),
          okText: t('app.gallery.moveToTrash'),
          cancelText: t('app.common.cancel'),
          onOk() {
            handleDeleteGallery({ forceDelete: true });
          },
        });
        return;
      }
      message.error(t(`app.message.gallery.delete.error.${camelCase(errorCode)}`));
    }
  };

  const handleOpenEmailModal = async (associatedModel: EmailTemplateAssociatedModel, contactId?: number) => {
    if (isNoEmailOnContact) {
      return modal.confirm({
        title: t('app.gallery.contact.noEmail.title'),
        content: t('app.gallery.contact.noEmail.content', {
          contact: gallery.contact?.displayName,
        }),
        okText: `${t('app.common.edit')} ${gallery.contact?.displayName}`,
        cancelText: t('app.common.cancel'),
        onOk() {
          if (gallery?.contact?.id) {
            updateContact(gallery.contact.id);
          }
        },
      });
    }

    openModal('SEND_EMAIL', {
      onSubmit: handleSendEmail,
      defaultValues: {
        title: '',
        content: '',
        contactId: contactId ? contactId : gallery?.contact?.id,
        // Don't pass contacts with no email to send email modal
        secondaryContactIds: gallery?.secondaryContacts?.filter(({ email }) => !!email).map(({ id }) => id) || [],
        templateAssociatedModel: associatedModel,
      },
      galleryId: gallery.id,
      contactId,
    });
  };

  const headerButtons = [
    <Button
      key="send"
      size="large"
      onClick={() => {
        handleOpenEmailModal(EmailTemplateAssociatedModel.GALLERY);
      }}
      icon={<Icon name="send" />}
    >
      {t('app.common.send')}
    </Button>,
    <Button
      key="edit"
      size="large"
      onClick={() => {
        openModal('GALLERY', {
          name: 'edit',
          onSubmit: handleEditOnSubmit,
          defaultValues: {
            id: gallery?.id,
            name: name || '',
            url: gallery?.url,
            video: gallery?.video,
            contactId: gallery?.contact?.id,
            projectId: gallery?.project?.id,
            status: gallery?.status,
            availableAt: gallery?.availableAt,
            expiredAt: gallery?.expiredAt,
            accessPolicy: gallery?.accessPolicy,
            accessCode: gallery?.accessCode ?? undefined,
            shootedAt: gallery?.shootedAt,
            inPortfolio: !!gallery?.inPortfolio,
            secondaryContactIds: gallery?.secondaryContacts?.map(({ id }) => id) || [],
            catalogId: gallery?.catalog?.id || null,
            catalogName: gallery?.catalog?.name,
            workmode: gallery?.workmode,
            locale: gallery?.locale,
            isEmailRequired: gallery?.isEmailRequired,
          },
        });
      }}
      icon={<Icon name="edit" />}
    >
      {t('app.common.edit')}
    </Button>,
    <Dropdown
      key="more"
      menu={{
        items: [
          {
            key: 'duplicate',
            label: t('app.gallery.convertToPreset'),
            onClick: () => {
              handleDuplicateGalleryPreset({ id: gallery.id });
            },
          },
          {
            key: 'archive',
            label: `${t('app.common.archive')} ${
              isActionsDisabled ? ` - ${t('app.gallery.archive.disabled')}` : ''
            }`.trim(),
            disabled: isActionsDisabled,
            onClick: () => {
              return modal.confirm({
                title: t('app.gallery.archive.title', { count: 1 }),
                content: t('app.gallery.archive.content'),
                okText: t('app.common.archive'),
                cancelText: t('app.common.cancel'),
                onOk() {
                  handleArchiveGallery({ forceArchiving: false });
                },
              });
            },
          },
          {
            key: 'delete',
            label: `${t('app.gallery.moveToTrash')} ${
              isActionsDisabled ? ` - ${t('app.gallery.delete.disabled')}` : ''
            }`.trim(),
            danger: true,
            disabled: isActionsDisabled,
            onClick: () => {
              return modal.confirm({
                title: t('app.confirm.moveToTrash', { count: 1 }),
                okText: t('app.gallery.moveToTrash'),
                cancelText: t('app.common.cancel'),
                onOk() {
                  handleDeleteGallery({ forceDelete: false });
                },
              });
            },
          },
        ],
      }}
      placement="bottomRight"
    >
      <RoundButton size="large" icon="more" />
    </Dropdown>,
  ];

  if (GALLERY_URL && galleryRootUrl) {
    headerButtons.unshift(
      <Button
        key="add"
        type="primary"
        size="large"
        onClick={() => {
          onVisit();

          window.open(galleryRootUrl, '_blank');
        }}
        icon={<Icon name="external-link" />}
      >
        {t('app.common.showGallery')}
      </Button>,
    );
  }

  const statCardProps: {
    minWidth: number;
    shadow: boolean;
    width: 'auto';
  } = {
    minWidth: 90,
    shadow: true,
    width: 'auto',
  };

  const title = name || t('app.common.gallery');

  return (
    <Flex vertical gap="middle">
      <Header
        title={
          <Flex align="center" gap="middle">
            <GalleryJobTitle jobs={jobs}>{title}</GalleryJobTitle>

            <Switch
              style={{ backgroundColor: gallery.status === GalleryStatus.ONLINE ? Colors.success : Colors.error }}
              checkedChildren={t(`app.gallery.status.online`)}
              unCheckedChildren={t(`app.gallery.status.offline`)}
              checked={gallery.status === GalleryStatus.ONLINE}
              onChange={checked => {
                if (handleChangeGalleryStatus) {
                  handleChangeGalleryStatus({
                    id: gallery?.id,
                    isChecked: checked,
                  });
                }
              }}
            />
          </Flex>
        }
        breadcrumbContent={[
          { text: t('app.menu.home'), url: '/app/dashboard' },
          { text: t('app.menu.lastGalleries'), url: '/app/galleries' },
          { text: name || t('app.common.gallery') },
        ]}
        buttons={headerButtons}
      />
      <Layout>
        <Flex vertical flex={1} gap="middle">
          <Flex gap="middle" wrap="wrap">
            {project && (
              <StatCard
                {...statCardProps}
                label={t('app.gallery.project')}
                value={project.name}
                href={`${CRM_URL}/app/projects/${project.id}`}
                target="_blank"
              />
            )}
            <StatCard
              {...statCardProps}
              label={t('app.common.contact')}
              value={contact ? contact.displayName : '-'}
              onClick={contact ? () => updateContact(contact.id) : undefined}
            />

            {!isGalleryPhotoCountLoading && (
              <StatCard {...statCardProps} label={t('app.common.photos')} value={galleryPhotoCount || 0} />
            )}
            <StatCard
              {...statCardProps}
              label={t('app.common.photosViewCount', { count: 2 })}
              value={_count?.photosViewCount || 0}
            />
            <StatCard {...statCardProps} label={t('app.common.folders')} value={_count?.folders || 0} />
            {catalog && (
              <StatCard
                {...statCardProps}
                label={t('app.common.catalog', { count: 1 })}
                value={catalog.name || '-'}
                href={`/app/catalogs/${catalog.id}`}
              />
            )}

            <StatCard
              {...statCardProps}
              label={t('app.gallery.includedPhotosCount.label')}
              value={gallery.hasAllPhotosIncluded ? t('app.gallery.allPhotos') : includedPhotosCount}
            />

            <StatCard
              {...statCardProps}
              label={t('app.gallery.workmode.label')}
              value={t(`app.gallery.settings.defaultWorkmode.${camelCase(workmode)}`)}
            />
            {workmode === GalleryWorkmode.READY_FOR_SALE && (
              <StatCard
                {...statCardProps}
                value={`${orders?.getOrders._count}`}
                label={t('app.common.order', { count: orders?.getOrders._count || 1 })}
              />
            )}
            {workmode === GalleryWorkmode.RETOUCH_FIRST && validatedOrders && orders && orders.getOrders._count > 0 ? (
              <StatCard
                {...statCardProps}
                value={`${validatedOrders?.getOrders._count} / ${orders?.getOrders._count}`}
                label={t('app.common.validatedOrder', { count: validatedOrders?.getOrders._count || 1 })}
              />
            ) : null}
          </Flex>
          {isOnlyDefaultFolder && (
            <Alert
              className={styles.alert}
              type="warning"
              message={t('app.gallery.onlyDefaultFolder.title')}
              description={t('app.gallery.onlyDefaultFolder.description')}
            />
          )}
          {retouchedPhotoCount !== undefined &&
            needRetouchPhotoCount !== undefined &&
            retouchedPhotoCount < needRetouchPhotoCount && (
              <Alert
                className={styles.alert}
                type="info"
                message={t('app.gallery.remainingRetouchedPhoto', {
                  count: needRetouchPhotoCount - retouchedPhotoCount,
                })}
                closable
              />
            )}

          <ContentContainer className={styles.tabsContainer} shadow rounded padding="baseMargin">
            <Tabs className={styles.tabs} defaultActiveKey="1" destroyInactiveTabPane>
              <Tabs.TabPane className={styles.tabPane} tab={t('app.common.photos')} key="photos">
                <GalleryPhotosTab photosOrder={photosOrder} photosCustomOrder={photosCustomOrder} />
              </Tabs.TabPane>
              {workmode === GalleryWorkmode.RETOUCH_FIRST && (
                <Tabs.TabPane className={styles.tabPane} tab={t('app.common.retouch', { count: 1 })} key="retouch">
                  <GalleryRetouchTab
                    onSendEmail={({ contactId }: OnSendEmailProps) => {
                      handleOpenEmailModal(EmailTemplateAssociatedModel.GALLERYORDERAVAILABLE, contactId);
                    }}
                  />
                </Tabs.TabPane>
              )}

              <Tabs.TabPane className={styles.tabPane} tab={t('app.gallery.tabs.appearance')} key="appearance">
                <GalleryAppearanceTab galleryId={gallery.id} />
              </Tabs.TabPane>

              <Tabs.TabPane className={styles.tabPane} tab={t('app.gallery.tabs.watermark')} key="watermark">
                <GalleryWatermarksTab
                  galleryId={galleryId}
                  currentWatermarkId={watermark?.id || null}
                  currentWatermarkMode={watermarkMode}
                  galleryAppearance={galleryAppearance}
                  galleryPhotoCount={galleryPhotoCount}
                />
              </Tabs.TabPane>

              <Tabs.TabPane className={styles.tabPane} tab={t('app.common.products', { count: 2 })} key="products">
                {galleryId && <GalleryProductsTab galleryId={galleryId} />}
              </Tabs.TabPane>

              <Tabs.TabPane className={styles.tabPane} tab={t('app.common.picklist', { count: 2 })} key="picklist">
                {galleryId && <GalleryPicklistsTab galleryId={galleryId} />}
              </Tabs.TabPane>

              {(gallery.accessPolicy === GalleryAccessPolicy.ACCESS_CODE ||
                gallery.accessPolicy === GalleryAccessPolicy.PUBLIC) && (
                <Tabs.TabPane className={styles.tabPane} tab={t('app.gallery.tabs.access')} key="access">
                  {galleryRootUrl && (
                    <GalleryAccessTab
                      galleryUrl={galleryRootUrl}
                      isClientCodesAdminEnabled={gallery.isClientCodesAdminEnabled}
                      accessPolicy={gallery.accessPolicy}
                      galleryContact={gallery.contact || null}
                      galleryId={gallery.id}
                      isEmailRequired={gallery.isEmailRequired}
                    />
                  )}
                </Tabs.TabPane>
              )}

              <Tabs.TabPane className={styles.tabPane} tab={t('app.common.order', { count: 2 })} key="orders">
                {galleryId && <GalleryOrdersTab galleryId={galleryId} />}
              </Tabs.TabPane>

              <Tabs.TabPane className={styles.tabPane} tab={t('app.common.comment', { count: 2 })} key="comment">
                {galleryId && <GalleryCommentsTab galleryId={galleryId} />}
              </Tabs.TabPane>

              <Tabs.TabPane className={styles.tabPane} tab={t('app.gallery.tabs.extra')} key="extra">
                {galleryId && (
                  <GalleryExtraTab video={gallery.video} spotifyUri={gallery.spotifyUri} galleryId={galleryId} />
                )}
              </Tabs.TabPane>
            </Tabs>
          </ContentContainer>
        </Flex>
      </Layout>
    </Flex>
  );
};

export default GalleriesShow;
