import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';

import { useMutation, useQuery, useReactiveVar } from '@apollo/client';
import { App, Button } from 'antd';
import { useNavigate } from 'react-router-dom';
import { Tutorial } from 'types/Tutorial';

import ContentContainer from 'Components/Atoms/ContentContainer';
import Icon from 'Components/Atoms/Icon';
import Layout from 'Components/Atoms/Layout';

import Header from 'Components/Molecules/Header';
import GalleryPresetTable from 'Components/Molecules/Tables/GalleryPresetTable';

import { NewGalleryPresetFormPayload } from 'Forms/GalleryPreset';

import { Metrics } from 'Themes';
import styled from 'Themes/Styled';

import { LocalizationContext } from 'i18n';

import { useModals } from 'Hooks/Modal';
import { useTutorial } from 'Hooks/useTutorial';

import { UserConfigVar } from 'Operations/Cache';

import { GalleryAccessPolicy, Locale } from 'Operations/__generated__/graphql';

import { GET_PAGINATED_GALLERY_PRESETS } from 'Operations/Queries/GalleryPreset/GetPaginatedGalleryPresets';

import { CREATE_GALLERY_PRESET } from 'Operations/Mutations/GalleryPreset/CreateGalleryPreset';
import { DELETE_GALLERY_PRESET } from 'Operations/Mutations/GalleryPreset/DeleteGalleryPreset';
import { DUPLICATE_GALLERY_PRESET } from 'Operations/Mutations/GalleryPreset/DuplicateGalleryPreset';
import updateUserConfig from 'Operations/Mutations/User/UpdateUserConfig';

const StyledTableContentContainer = styled(ContentContainer)`
  flex: 1;
  align-self: flex-start;
  margin-top: ${Metrics.baseMargin}px;
`;

const whereCondition = {
  perPage: 20,
};

const GalleryPresetsIndex = () => {
  const { t, locale } = useContext(LocalizationContext);
  const { message } = App.useApp();
  const { openModal, closeModal } = useModals();
  const navigate = useNavigate();
  const { openTutorial, closeTutorial } = useTutorial();
  const [page, setPage] = useState(1);
  const userConfig = useReactiveVar(UserConfigVar);

  const {
    data,
    loading: isLoadingPresets,
    error: presetsError,
  } = useQuery(GET_PAGINATED_GALLERY_PRESETS, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    variables: {
      where: {
        ...whereCondition,
        page,
      },
    },
  });

  const [createGalleryPreset] = useMutation(CREATE_GALLERY_PRESET);
  const [deleteGallery] = useMutation(DELETE_GALLERY_PRESET, {
    onCompleted() {
      if (data && data?.getPaginatedGalleryPresets?._count % whereCondition.perPage === 0 && page > 1) {
        setPage(page - 1);
      }
    },
  });
  const [duplicateGalleryPreset] = useMutation(DUPLICATE_GALLERY_PRESET);

  const shouldDisplayTutorial = useMemo(
    () =>
      !isLoadingPresets &&
      !presetsError &&
      data?.getPaginatedGalleryPresets._count === 0 &&
      (!userConfig.openedTutorials ||
        (userConfig.openedTutorials && !userConfig.openedTutorials[Tutorial.GalleryPresets])),
    [isLoadingPresets, data?.getPaginatedGalleryPresets._count],
  );

  useEffect(() => {
    if (shouldDisplayTutorial) {
      openTutorial({ tutorial: Tutorial.GalleryPresets });
      updateUserConfig({ openedTutorials: { ...userConfig.openedTutorials, [Tutorial.GalleryPresets]: true } });
    }

    return () => {
      if (shouldDisplayTutorial) {
        closeTutorial();
      }
    };
  }, [shouldDisplayTutorial]);

  const handleFetchMore = useCallback(
    ({ page: newPage }: { page: number }) => {
      setPage(newPage);
    },
    [setPage],
  );

  const handleDelete = useCallback(
    ({ id }: { id: number }) => {
      deleteGallery({
        variables: {
          where: {
            id,
          },
        },
        update(cache, { data }) {
          if (data && data.deleteGalleryPreset.id) {
            const normalizedId = cache.identify({
              id: data.deleteGalleryPreset.id,
              __typename: data.deleteGalleryPreset.__typename,
            });
            cache.evict({
              id: normalizedId,
            });

            cache.gc();
          }
        },
      });

      message.success(t('app.message.galleryPreset.delete.success'));
    },
    [deleteGallery, t],
  );

  const handleOnSubmit = useCallback(
    async ({ values, formikBag }: NewGalleryPresetFormPayload) => {
      try {
        if (values.catalogId) {
          const galleryPreset = await createGalleryPreset({
            variables: {
              data: {
                ...values,
                catalogId: values.catalogId,
              },
            },
          });

          message.success(t('app.message.galleryPreset.create.success'));
          formikBag.setSubmitting(false);
          closeModal();

          if (galleryPreset.data?.createGalleryPreset) {
            navigate(`/app/galleries/presets/${galleryPreset.data.createGalleryPreset.id}`);
          }
        } else {
          throw new Error('Catalog ID is required');
        }
      } catch (error) {
        console.log(error);

        message.error(t('app.message.error.somethingWentWrong'));

        formikBag.setSubmitting(false);
      }
    },
    [closeModal, createGalleryPreset, navigate, t],
  );

  const handleOnCreateGalleryPresetClicked = useCallback(() => {
    openModal('GALLERY_PRESET', {
      name: 'new',
      onSubmit: handleOnSubmit,
      defaultValues: {
        name: '',
        locale: locale === 'fr' ? Locale.FR : Locale.EN,
        accessPolicy: GalleryAccessPolicy.PUBLIC,
        isEmailRequired: false,
      },
    });
  }, [handleOnSubmit, locale, openModal]);

  const handleDuplicateGalleryPreset = useCallback(
    async ({ id }: { id: number }) => {
      const preset = await duplicateGalleryPreset({
        variables: {
          where: {
            galleryPresetId: id,
          },
        },
      });

      message.success(t('app.message.galleryPreset.duplicate.success'));

      if (preset.data?.duplicateGalleryPreset.id) {
        navigate(`/app/galleries/presets/${preset.data.duplicateGalleryPreset.id}`);
      }
    },
    [duplicateGalleryPreset, t],
  );

  return (
    <>
      <Header
        title={t('app.common.galleryPresets')}
        breadcrumbContent={[
          { text: t('app.menu.home'), url: '/app/dashboard' },
          { text: t('app.common.galleryPresets') },
        ]}
        buttons={[
          <Button
            key="add"
            type="primary"
            size="large"
            onClick={handleOnCreateGalleryPresetClicked}
            icon={<Icon name="add" />}
          >
            {t('app.common.add')}
          </Button>,
        ]}
      />
      <Layout>
        <StyledTableContentContainer>
          <GalleryPresetTable
            total={data?.getPaginatedGalleryPresets ? data?.getPaginatedGalleryPresets._count : 0}
            data={data?.getPaginatedGalleryPresets.edges}
            loading={isLoadingPresets}
            paginationSize={whereCondition.perPage}
            handleOnChange={handleFetchMore}
            handleDeleteConfirm={handleDelete}
            handleDuplicate={handleDuplicateGalleryPreset}
          />
        </StyledTableContentContainer>
      </Layout>
    </>
  );
};

export default GalleryPresetsIndex;
