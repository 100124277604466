import { gql } from 'Operations/__generated__';

export const GET_ORDER_LIST = gql(/* GraphQL */ `
  query GetOrderList($where: GetOrdersWhereInput!) {
    getOrders(where: $where) {
      _count
      edges {
        id
        gallery {
          id
          userId
          name
          contact {
            ...ContactCoreFields
          }
        }
        createdAt
        inTransitAt
        isPaid
        isValidated
        invoice {
          id
          url
          ttcPrice
          isReceipt
        }
        ... on OrderAdmin {
          status
          orderedByFotostudio
          photographerMustReceiveOrder
          handlers
          laboOrderProcessedAt
          seller {
            email
            companyName
            companyAddress
            street
            zipcode
            city
            country
          }
          contact {
            ...ContactCoreFields
          }
        }
      }
    }
  }
`);
