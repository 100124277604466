import i18n from 'i18n';

import dayjs from 'Services/DayjsService';

import * as yup from 'yup';

yup.setLocale({
  mixed: {
    required: () => i18n.t('app.form.errors.required'),
    notType: () => i18n.t('app.form.errors.required'),
  },
  string: {
    required: () => i18n.t('app.form.errors.required'),
    email: ({ path }: { path: string }) => i18n.t('app.form.errors.email', { path }),
    max: ({ max }: { max: number }) => i18n.t('app.form.errors.string.max', { max }),
    trim: () => i18n.t('app.form.errors.string.trim'),
  },
  number: {
    min: ({ min }: { min: number }) => i18n.t('app.form.errors.number.min', { min }),
    moreThan: ({ more }: { more: number }) => i18n.t('app.form.errors.number.min', { min: more }),
    positive: () => i18n.t('app.form.errors.number.min', { min: 0 }),
    integer: () => i18n.t('app.form.errors.number.integer'),
  },
  array: {
    min: ({ min }: { min: number }) => i18n.t('app.form.errors.array.min', { min, count: min }),
  },
});

yup.addMethod(yup.string, 'after', function (ref) {
  return this.test({
    name: 'after',
    exclusive: false,
    message: () =>
      i18n.t('app.form.errors.date.after', {
        reference: i18n.t(`app.common.${ref.path}`).toLowerCase(),
      }),
    params: {
      reference: ref.path,
    },
    test: function (value, { schema }) {
      const isNullable = schema?.spec?.nullable;
      if (isNullable && (value === null || value === undefined || value === '')) {
        return true;
      }

      return dayjs(value).isAfter(dayjs(this.resolve(ref)));
    },
  });
});

yup.addMethod(yup.string, 'host', function () {
  return this.test({
    name: 'host',
    exclusive: false,
    message: () => i18n.t('app.form.errors.string.host'),
    test: function (value, { schema }) {
      const isNullable = schema?.spec?.nullable;
      if (isNullable && value === null) {
        return true;
      }
      // Check if value is a valid hostname
      return !!value?.match(
        /^(((?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?))|((http(s)?:\/\/)?(([a-zA-Z0-9]|[a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9])\.)+([A-Za-z]|[A-Za-z][A-Za-z0-9-]*[A-Za-z0-9])))(:(?!$)((6553[0-5])|(655[0-2][0-9])|(65[0-4][0-9]{2})|(6[0-4][0-9]{3})|([1-5][0-9]{4})|([0-5]{0,5})|([0-9]{1,4})))?$/,
      );
    },
  });
});

yup.addMethod(yup.string, 'spotifyUri', function () {
  return this.test({
    name: 'spotifyUri',
    exclusive: false,
    message: () => i18n.t('app.form.errors.string.spotifyUri'),
    test: function (value, { schema }) {
      const isNullable = schema?.spec?.nullable;
      if (isNullable && value === null) {
        return true;
      }

      return (
        !!value &&
        (value.includes('track:') ||
          value.includes('album:') ||
          value.includes('playlist:') ||
          value.includes('artist:'))
      );
    },
  });
});

export default yup;
